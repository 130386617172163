import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PlusCircle, Trash2, Calendar, AlertTriangle } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import API_URL from '../../constants/Constants';
import SidebarLayout from '../Layout/SidebarLayout';

const UpdateTrainingPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [training, setTraining] = useState({
    title: '',
    description: '',
    duration: '',
    instructor: '',
    fee: '',
    level: 'Beginner',
    is_certified: false,
    what_you_will_learn: [''],
    address: ''
  });

  const [schedules, setSchedules] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    const fetchTrainingDetails = async () => {
      try {
        const trainingResponse = await axios.get(`${API_URL}training/${id}`);
        
        // Fetch only schedules for this specific training
        const schedulesResponse = await fetch(`${API_URL}training-schedules/training/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        const schedulesData = await schedulesResponse.json();
        
        setTraining({
          ...trainingResponse.data,
          what_you_will_learn: trainingResponse.data.what_you_will_learn || ['']
        });
        
        // Check if schedulesData is an array before setting
        setSchedules(Array.isArray(schedulesData) ? schedulesData : []);
      } catch (error) {
        console.error('Error fetching training details:', error);
        alert('Failed to fetch training details');
        // Set schedules to an empty array in case of error
        setSchedules([]);
      }
    };
  
    fetchTrainingDetails();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setTraining(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleLearningOutcomeChange = (index, value) => {
    const newOutcomes = [...training.what_you_will_learn];
    newOutcomes[index] = value;
    setTraining(prev => ({ ...prev, what_you_will_learn: newOutcomes }));
  };

  const addLearningOutcome = () => {
    setTraining(prev => ({
      ...prev, 
      what_you_will_learn: [...prev.what_you_will_learn, '']
    }));
  };

  const addSchedule = () => {
    setSchedules([...schedules, { start_date: '', end_date: '' }]);
  };

  const updateSchedule = (index, field, value) => {
    const newSchedules = [...schedules];
    newSchedules[index][field] = value;
    setSchedules(newSchedules);
  };

  const removeSchedule = (index) => {
    const newSchedules = schedules.filter((_, i) => i !== index);
    setSchedules(newSchedules);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Update training
      await axios.put(`${API_URL}training/${id}`, training);

      // Delete existing schedules
      await Promise.all(
        schedules.filter(s => s.id).map(schedule => 
          axios.delete(`${API_URL}training-schedules/${schedule.id}`)
        )
      );

      // Create new schedules
      const schedulePromises = schedules.map(schedule => 
        axios.post(`${API_URL}training-schedules`, {
          training_id: Number(id),
          ...schedule
        })
      );

      await Promise.all(schedulePromises);

      alert('Training updated successfully!');
      navigate('/trainings');
    } catch (error) {
      console.error('Error updating training:', error);
      alert('Failed to update training');
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${API_URL}training/${id}`);
      alert('Training deleted successfully!');
      navigate('/trainings');
    } catch (error) {
      console.error('Error deleting training:', error);
      alert('Failed to delete training');
    }
  };

  return (
    <SidebarLayout>
       <div className="max-w-4xl m-8 mx-auto p-6 bg-white rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Update Training</h2>
        <button 
          onClick={() => setShowDeleteConfirm(true)}
          className="flex items-center text-red-500 hover:text-red-600"
        >
          <Trash2 className="mr-2 w-5 h-5" /> Delete Training
        </button>
      </div>

      {showDeleteConfirm && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4 flex items-center">
          <AlertTriangle className="mr-4 text-yellow-600 w-6 h-6" />
          <div>
            <p className="text-yellow-700 font-semibold">Are you sure you want to delete this training?</p>
            <div className="mt-2 flex space-x-2">
              <button 
                onClick={handleDelete}
                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
              >
                Confirm Delete
              </button>
              <button 
                onClick={() => setShowDeleteConfirm(false)}
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

<form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid md:grid-cols-2 gap-4">
          <div>
            <label className="block mb-2">Training Title</label>
            <input
              type="text"
              name="title"
              value={training.title}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div>
            <label className="block mb-2">Training Provider</label>
            <input
              type="text"
              name="instructor"
              value={training.instructor}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
        </div>

        <div>
          <label className="block mb-2">Description</label>
          <textarea
            name="description"
            value={training.description}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg"
            rows="4"
            required
          />
        </div>

        <div className="grid md:grid-cols-3 gap-4">
          <div>
            <label className="block mb-2">Duration (Days)</label>
            <input
              type="number"
              name="duration"
              value={training.duration}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div>
            <label className="block mb-2">Fee ($)</label>
            <input
              type="number"
              name="fee"
              value={training.fee}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div>
            <label className="block mb-2">Level</label>
            <select
              name="level"
              value={training.level}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg"
            >
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Advanced">Advanced</option>
            </select>
          </div>
        </div>

        <div>
          <label className="block mb-2">Address</label>
          <input
            type="text"
            name="address"
            value={training.address}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg"
            required
          />
        </div>

        <div className="flex items-center mb-2">
          <input
            type="checkbox"
            name="is_certified"
            checked={training.is_certified}
            onChange={handleChange}
            className="mr-2"
          />
          <label>Certification Available</label>
        </div>

        <div>
          <div className="flex justify-between items-center mb-2">
            <h3 className="font-semibold">Learning Outcomes</h3>
            <button
              type="button"
              onClick={addLearningOutcome}
              className="flex items-center text-blue-500 hover:text-blue-600"
            >
              <PlusCircle className="mr-2 w-5 h-5" /> Add Outcome
            </button>
          </div>
          {training.what_you_will_learn.map((outcome, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                value={outcome}
                onChange={(e) => handleLearningOutcomeChange(index, e.target.value)}
                className="flex-grow px-3 py-2 border rounded-lg mr-2"
                placeholder="Learning outcome"
              />
            </div>
          ))}
        </div>

        <div className="border-t pt-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="font-semibold">Training Schedules</h3>
            <button
              type="button"
              onClick={addSchedule}
              className="flex items-center text-blue-500 hover:text-blue-600"
            >
              <Calendar className="mr-2 w-5 h-5" /> Add Schedule
            </button>
          </div>
          {schedules.map((schedule, index) => (
            <div key={index} className="flex items-center mb-2 space-x-2">
              <div className="flex-grow">
                <label className="block text-sm mb-1">Start Date</label>
                <input
                  type="date"
                  value={schedule.start_date}
                  onChange={(e) => updateSchedule(index, 'start_date', e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg"
                  required
                />
              </div>
              <div className="flex-grow">
                <label className="block text-sm mb-1">End Date</label>
                <input
                  type="date"
                  value={schedule.end_date}
                  onChange={(e) => updateSchedule(index, 'end_date', e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg"
                  required
                />
              </div>
              <button
                type="button"
                onClick={() => removeSchedule(index)}
                className="text-red-500 hover:text-red-600 self-end mb-2"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            </div>
          ))}
        </div>

        <div className="text-right">
          <button
            type="submit"
            className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
          >
            Update Training
          </button>
        </div>
      </form>
    </div>
    </SidebarLayout>
   
  );
};

export default UpdateTrainingPage;