// import React from 'react';
// import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

// // Import components
// import Login from './components/Auth/Login';
// import Register from './components/Auth/Register';
// import HomePage from './pages/HomePage';
// import DashboardPage from './pages/DashboardPage';
// import AdminPage from './pages/AdminPage';
// import CreateCourse from './components/Trainings/CreateCourse';
// import CategoryList from './components/Categories/CategoryList';
// import CreateCategory from './components/Categories/CreateCategory';
// import EnrollmentForm from './components/Enrollments/EnrollmentForm';
// import EnrollmentConfirmation from './components/Enrollments/EnrollmentConfirmation';

// // Import layout components
// import Navbar from './components/Layout/Navbar';
// import Footer from './components/Layout/Footer';
// import TrainingList from './components/Trainings/TrainingList';
// import TrainingDetail from './components/Trainings/TrainingDetail';
// import AboutPage from './pages/AboutPage';
// import ContactPage from './pages/ContactPage';
// import AddTrainingPage from './components/Trainings/AddTrainingPage';
// import UpdateTrainingPage from './components/Trainings/UpdateTrainingPage.js';

// // Layout wrapper component
// const Layout = ({ children }) => {
//   const location = useLocation();
//   const hideLayoutPaths = ['/dashboard', '/admin'];
//   const shouldShowLayout = !hideLayoutPaths.includes(location.pathname);

//   return (
//     <div className="flex flex-col min-h-screen">
//       {shouldShowLayout && <Navbar />}
//       <main className="flex-grow">
//         {children}
//       </main>
//       {shouldShowLayout && <Footer />}
//     </div>
//   );
// };

// function App() {
//   return (
//     <Router>
//       <Layout>
//         <Routes>
//           {/* Public Routes */}
//           <Route path="/" element={<HomePage />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/register" element={<Register />} />
//           <Route path="/about" element={<AboutPage />} />
//           <Route path="/contact" element={<ContactPage />} />

//           {/* Course Routes */}
//           <Route path="/trainings" element={<TrainingList />} />
//           {/* <Route path="/trainings/create" element={<AddTrainingPage />} /> */}
//           <Route path="/trainings/:id" element={<TrainingDetail />} />
//           <Route path="/trainings/update/:id" element={<UpdateTrainingPage />} />
//           <Route path="/courses/create" element={<CreateCourse />} />


//           {/* Category Routes */}
//           <Route path="/categories" element={<CategoryList />} />
//           <Route path="/categories/create" element={<CreateCategory />} />

//           {/* Enrollment Routes */}
//           <Route path="/enroll/:courseId" element={<EnrollmentForm />} />
//           <Route path="/enrollment/confirmation" element={<EnrollmentConfirmation />} />

//           {/* Admin Routes */}
//           <Route path="/dashboard" element={<DashboardPage />} />
//           <Route path="/admin" element={<AdminPage />} />
//           <Route path="/admin/trainings/create" element={<AddTrainingPage />} />


//           {/* 404 Route */}
//           <Route
//             path="*"
//             element={
//               <div className="flex flex-col items-center justify-center h-96">
//                 <h1 className="text-4xl font-bold mb-4">404</h1>
//                 <p className="text-xl">Page Not Found</p>
//               </div>
//             }
//           />
//         </Routes>
//       </Layout>
//     </Router>
//   );
// }

// export default App;

import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Import components (ensure these are correctly imported)
import Navbar from './components/Layout/Navbar';
import Footer from './components/Layout/Footer';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import HomePage from './pages/HomePage';
import DashboardPage from './pages/DashboardPage';
import AdminPage from './pages/AdminPage';
import CreateCourse from './components/Trainings/CreateCourse';
import CategoryList from './components/Categories/CategoryList';
import CreateCategory from './components/Categories/CreateCategory';
import EnrollmentForm from './components/Enrollments/EnrollmentForm';
import EnrollmentConfirmation from './components/Enrollments/EnrollmentConfirmation';
import TrainingList from './components/Trainings/TrainingList';
import TrainingDetail from './components/Trainings/TrainingDetail';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import AddTrainingPage from './components/Trainings/AddTrainingPage';
import UpdateTrainingPage from './components/Trainings/UpdateTrainingPage';
import CategoriesPage from './components/Trainings/CategoriesPage';
import AddCategoryPage from './components/Categories/AddCategoryPage';
import ViewCategoryPage from './components/Categories/ViewCategoryPage';
import EditCategoryPage from './components/Categories/EditCategoryPage';
import CategoryPage from './components/Categories/CategoryPage';
import TrainingDashboard from './components/Trainings/TrainingDashboard';
import EnrollmentDashboard from './components/Enrollments/EnrollmentDashboard';
import ViewTrainingPage from './components/Trainings/ViewTrainingPage';

// Layout wrapper component
// const Layout = ({ children }) => {
//   const location = useLocation();
//   const hideLayoutPaths = ['/dashboard', '/admin'];
//   const shouldShowLayout = !hideLayoutPaths.includes(location.pathname);

//   return (
//     <div className="flex flex-col min-h-screen">
//       {shouldShowLayout && <Navbar />}
//       <main className="flex-grow">
//         {children}
//       </main>
//       {shouldShowLayout && <Footer />}
//     </div>
//   );
// };

const Layout = ({ children }) => {
  const location = useLocation();
  const hideLayoutPaths = ['/dashboard', '/admin', '/admin/'];
  
  const shouldShowLayout = !hideLayoutPaths.some(path => 
    location.pathname === path || location.pathname.startsWith(path)
  );

  return (
    <div className="flex flex-col min-h-screen">
      {shouldShowLayout && <Navbar />}
      <main className="flex-grow">
        {children}
      </main>
      {shouldShowLayout && <Footer />}
    </div>
  );
};

function App() {
  return (
    <HelmetProvider>
      <Helmet
        defaultTitle="Learning Platform"
        titleTemplate="%s | Your Learning Journey"
      >
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Router>
        <Layout>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />

            {/* Training Routes */}
            <Route path="/trainings" element={<TrainingList />} />
            <Route path="/trainings/:id" element={<TrainingDetail />} />
            <Route path="/trainings/update/:id" element={<UpdateTrainingPage />} />
            <Route path="/courses/create" element={<CreateCourse />} />


            {/* Enrollment Routes */}
            <Route path="/enroll/:courseId" element={<EnrollmentForm />} />
            <Route path="/enrollment/confirmation" element={<EnrollmentConfirmation />} />

            {/* Admin Routes */}
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/admin" element={<DashboardPage />} />
            <Route path="/admin/trainings/create" element={<AddTrainingPage />} />

            {/* Category Routes */}
            <Route path="/admin/categories" element={<CategoriesPage />} />
            <Route path="/admin/categories/create" element={<AddCategoryPage />} />
            <Route path="/admin/categories/view/:id" element={<ViewCategoryPage />} />
            <Route path="/admin/categories/update/:id" element={<EditCategoryPage />} />

            <Route path="/categories" element={<CategoriesPage />} />
            <Route path="/category/:categoryId" element={<CategoryPage />} />

            <Route path="/admin/trainings" element={<TrainingDashboard />} />
            <Route path="/admin/trainings/:id" element={<TrainingDetail />} />
            <Route path="/admin/trainings/update/:id" element={<UpdateTrainingPage />} />
            <Route path="/admin/trainings/view/:id" element={<ViewTrainingPage />} />
            <Route path="/admin/students" element={<EnrollmentDashboard />} />

            {/* 404 Route */}
            <Route
              path="*"
              element={
                <div className="flex flex-col items-center justify-center h-96">
                  <h1 className="text-4xl font-bold mb-4">404</h1>
                  <p className="text-xl">Page Not Found</p>
                </div>
              }
            />
          </Routes>
        </Layout>
      </Router>
    </HelmetProvider>
  );
}

export default App;