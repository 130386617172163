// import React from 'react';
// import { CheckCircle, FileText, Calendar } from 'lucide-react';

// const EnrollmentConfirmation = ({ courseDetails }) => {
//   // Simulated course details - in real app, this would come from props or context
//   const course = courseDetails || {
//     name: "Web Development Bootcamp",
//     instructor: "John Doe",
//     startDate: "2024-02-15",
//     duration: "12 weeks",
//     price: 499.99
//   };

//   return (
//     <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
//       <div className="bg-white shadow-xl rounded-lg max-w-md w-full p-8 text-center">
//         <CheckCircle className="mx-auto text-green-500" size={80} />
//         <h1 className="text-3xl font-bold text-gray-800 mt-4">Registration Confirmed!</h1>
        
//         <div className="mt-6 text-left space-y-4">
//           <div className="flex items-center space-x-4">
//             <FileText className="text-blue-500" />
//             <div>
//               <p className="font-semibold">Course</p>
//               <p>{course.name}</p>
//             </div>
//           </div>
          
//           <div className="flex items-center space-x-4">
//             <Calendar className="text-purple-500" />
//             <div>
//               <p className="font-semibold">Start Date</p>
//               <p>{course.startDate}</p>
//             </div>
//           </div>
          
//           <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mt-4">
//             <p className="text-sm text-blue-800">
//               An email confirmation has been sent to your registered email address. 
//               Please check your inbox for course details and next steps.
//             </p>
//           </div>
//         </div>

//         <div className="mt-6 grid grid-cols-1 gap-4">
//           <button className="bg-green-500 text-white py-2 rounded hover:bg-green-600 transition">
//             View Course Details
//           </button>
//           {/* <button className="bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition">
//             Go to Dashboard
//           </button> */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EnrollmentConfirmation;

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckCircle, Calendar, FileText, Clock, CreditCard, User, Mail, Phone, MapPin } from 'lucide-react';
import { motion } from 'framer-motion';

const EnrollmentConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { training, schedule, enrollmentDetails } = location.state || {};

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  if (!training || !schedule || !enrollmentDetails) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 flex items-center justify-center p-4">
        <div className="w-full max-w-lg text-center bg-white rounded-xl shadow-xl p-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">No enrollment details found</h2>
          <button
            onClick={() => navigate('/trainings')}
            className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-300 transform hover:scale-105"
          >
            Browse Courses
          </button>
        </div>
      </div>
    );
  }

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 }
  };

  const DetailItem = ({ icon: Icon, label, value }) => (
    <div className="flex items-start space-x-3 p-4 rounded-lg bg-gray-50 hover:bg-blue-50 transition-colors duration-300">
      <Icon className="w-5 h-5 text-blue-600 mt-1" />
      <div>
        <p className="text-sm font-medium text-gray-500">{label}</p>
        <p className="text-lg font-semibold text-gray-900">{value}</p>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <motion.div 
          className="text-center mb-12"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="inline-block p-4 bg-green-100 rounded-full mb-6 shadow-lg">
            <CheckCircle className="w-16 h-16 text-green-500" />
          </div>
          <h1 className="text-5xl font-bold text-gray-900 mb-4 bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
            Registration Successful!
          </h1>
          <p className="text-xl text-gray-600">
            Thank you for registering in our course. Your registration has been confirmed.
          </p>
        </motion.div>

        <motion.div {...fadeInUp} className="mb-8">
          <div className="bg-white rounded-xl shadow-xl overflow-hidden">
            <div className="p-6 border-b border-gray-100">
              <h2 className="text-2xl font-bold text-gray-900">Course Details</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <DetailItem icon={FileText} label="Course Name" value={training.title} />
                <DetailItem 
                  icon={Calendar} 
                  label="Course Duration" 
                  value={`${formatDate(schedule.start_date)} - ${formatDate(schedule.end_date)}`} 
                />
                <DetailItem icon={Clock} label="Schedule" value={schedule.time || "Full-time"} />
                <DetailItem icon={CreditCard} label="Course Fee" value={`${training.fee} $`} />
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div {...fadeInUp} className="mb-8">
          <div className="bg-white rounded-xl shadow-xl overflow-hidden">
            <div className="p-6 border-b border-gray-100">
              <h2 className="text-2xl font-bold text-gray-900">Your Information</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <DetailItem icon={User} label="Full Name" value={enrollmentDetails.fullname} />
                <DetailItem icon={Mail} label="Email" value={enrollmentDetails.email} />
                <DetailItem icon={Phone} label="Phone" value={enrollmentDetails.phone} />
                <DetailItem icon={MapPin} label="Address" value={enrollmentDetails.address} />
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div 
          className="bg-blue-100 border-l-4 border-blue-600 p-6 rounded-lg mb-8 shadow-lg"
          {...fadeInUp}
        >
          <p className="text-blue-800 text-lg">
            A confirmation email has been sent to{' '}
            <span className="font-semibold">{enrollmentDetails.email}</span>. 
            Please check your inbox for detailed course information and next steps.
          </p>
        </motion.div>

        <motion.div 
          className="flex flex-col sm:flex-row gap-4 justify-center"
          {...fadeInUp}
        >
          <button
            onClick={() => navigate('/trainings')}
            className="px-8 py-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-300 transform hover:scale-105 font-semibold shadow-lg"
          >
            Browse More Courses
          </button>
          
        </motion.div>
      </div>
    </div>
  );
};

export default EnrollmentConfirmation;