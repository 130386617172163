import React, { useState, useEffect } from 'react';
import { Calendar, Clock, Users, Target, Award, BookOpen } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import API_URL from '../../constants/Constants';

const TrainingDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [training, setTraining] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [schedulesError, setSchedulesError] = useState(null);

  useEffect(() => {
    const fetchTrainingDetails = async () => {
      try {
        // Fetch training details
        const trainingResponse = await fetch(`${API_URL}training/${id}`);
        if (!trainingResponse.ok) {
          throw new Error('Failed to fetch training details');
        }
        const trainingData = await trainingResponse.json();
        setTraining(trainingData);

        // Fetch training schedules
        try {
          const schedulesResponse = await fetch(`${API_URL}training-schedules/training/${id}`);
          if (!schedulesResponse.ok) {
            // Instead of throwing an error, we'll set an empty array and allow rendering
            setSchedules([]);
            setSchedulesError('No training schedules available');
          } else {
            const schedulesData = await schedulesResponse.json();
            setSchedules(schedulesData);
          }
        } catch (schedulesError) {
          console.error('Schedules fetch error:', schedulesError);
          setSchedules([]);
          setSchedulesError('Unable to fetch training schedules');
        }

        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchTrainingDetails();
  }, [id]);

  const handleEnrollment = (schedule) => {
    navigate(`/enroll/${id}`, {
      state: {
        training: training,
        schedule: schedule
      }
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  if (isLoading) {
    return <div className="text-center py-10">Loading training details...</div>;
  }

  if (!training) {
    return <div className="text-center py-10">No training found</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-white shadow-sm">
        <div className="max-w-6xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h1 className="text-4xl font-bold text-gray-900 mb-4">{training.title}</h1>
              <p className="text-lg text-gray-600 mb-6">
                {training.description}
              </p>
              <div className="flex flex-wrap gap-4">
                <span className="inline-flex items-center px-4 py-2 bg-blue-50 text-blue-700 rounded-full">
                  <Clock className="w-4 h-4 mr-2" /> {training.duration} Days
                </span>
                <span className="inline-flex items-center px-4 py-2 bg-green-50 text-green-700 rounded-full">
                  <Award className="w-4 h-4 mr-2" /> {training.is_certified ? 'Certificate Included' : 'No Certificate'}</span>
              </div>
            </div>
            <div className="relative">
              <img
                src="/images/1.jpg"
                alt={training.title}
                className="rounded-lg shadow-lg object-cover w-full h-[300px]"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-6xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-3 gap-8">
          {/* Left Column - Course Details */}
          <div className="md:col-span-2 space-y-8">
            <section className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-2xl font-semibold text-gray-900 mb-6">What You'll Learn</h2>
              <div className="grid sm:grid-cols-2 gap-4">
                {training.what_you_will_learn.map((skill, index) => (
                  <div key={index} className="flex items-start">
                    <Target className="w-5 h-5 text-blue-600 mt-1 mr-3" />
                    <div>
                      <h3 className="font-medium text-gray-900">{skill}</h3>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            <section className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-2xl font-semibold text-gray-900 mb-6">Course Schedule</h2>
              <div className="overflow-hidden">
                {schedules.length > 0 ? (
                  <table className="min-w-full">
                    <thead>
                      <tr className="border-b border-gray-200">
                        <th className="text-left py-3 px-4 font-semibold text-gray-900">Training Dates</th>
                        <th className="text-right py-3 px-4"></th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {schedules.map((schedule) => (
                        <tr key={schedule.id}>
                          <td className="py-4 px-4 flex items-center">
                            <Calendar className="w-5 h-5 text-blue-600 mr-3" />
                            <span className="text-gray-900">
                              {formatDate(schedule.start_date)} - {formatDate(schedule.end_date)}
                            </span>
                          </td>
                          <td className="py-4 px-4 text-right">
                            <button
                              onClick={() => handleEnrollment(schedule)}
                              className="inline-flex items-center px-4 py-2 border border-blue-600 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-50 transition-colors"
                            >
                              Register
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center py-6 text-gray-500">
                    {schedulesError || 'No upcoming schedules available for this training'}
                  </div>
                )}
              </div>
            </section>
          </div>

          {/* Right Column - Price Card */}
          <div className="md:col-span-1">
            <div className="bg-white rounded-xl shadow-sm p-6 sticky top-4">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">Course Fee</h2>
              <div className="text-3xl font-bold text-gray-900 mb-6">
                {training.fee} $
              </div>
              <div className="space-y-4 mb-6">
                <div className="flex items-center text-gray-600">
                  <Clock className="w-5 h-5 mr-3" />
                  <span>{training.duration} days intensive training</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <Award className="w-5 h-5 mr-3" />
                  <span>{training.is_certified ? 'Certificate of completion' : 'No certificate'}</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <Users className="w-5 h-5 mr-3" />
                  <span>By {training.instructor}</span>
                </div>
              </div>
{/* 
              <button
                onClick={() => handleEnrollment(schedules[0])}
                className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition-colors"
              >
                Register Now
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingDetail;