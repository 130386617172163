import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Create the root React element
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the application
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Optional: Add error reporting or performance monitoring
if (process.env.NODE_ENV === 'production') {
  // You can add error reporting services here
  // Example: 
  // import * as Sentry from "@sentry/react";
  // Sentry.init({ dsn: "your-sentry-dsn" });
}