
import React, { useState, useEffect } from 'react';
import { Search, PlusCircle, MoreVertical, Edit, Trash2, Eye } from 'lucide-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import API_URL from '../../constants/Constants';
import SidebarLayout from '../Layout/SidebarLayout';

const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_URL}categories`);
        setCategories(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleAddTraining = () => {
    navigate('/admin/categories/create');
  };

  const handleUpdateTraining = (id) => {
    navigate(`/admin/categories/update/${id}`);
  };

  const handleViewTraining = (id) => {
    navigate(`/admin/categories/view/${id}`);
  };

  const handleDeleteConfirmation = (training) => {
    setDeleteConfirmation(training);
  };

  const handleDeleteTraining = async () => {
    if (!deleteConfirmation) return;

    try {
      await axios.delete(`${API_URL}training/${deleteConfirmation.id}`);
      setCategories(categories.filter(t => t.id !== deleteConfirmation.id));
      setDeleteConfirmation(null);
    } catch (err) {
      console.error('Error deleting training:', err);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching categories</div>;

  return (
    <SidebarLayout>
          <div className="bg-white rounded-lg shadow">
      <div className="p-6 border-b">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <h2 className="text-2xl font-bold">Categories</h2>
          <div className="flex flex-col md:flex-row gap-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search Categories ..."
                className="pl-10 pr-4 py-2 border rounded-lg w-full md:w-64"
              />
              <Search className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
            </div>
            <button
              onClick={handleAddTraining}
              className="flex items-center justify-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            >
              <PlusCircle className="w-5 h-5" />
              Add Category
            </button>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                ID
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {categories.map((category) => (
              <tr key={category.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="font-medium">{category.id}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {category.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {category.description}
                </td>
                
                <td className="px-6 py-4 whitespace-nowrap text-right flex justify-end space-x-2">
                  <button 
                    onClick={() => handleViewTraining(category.id)} 
                    className="text-gray-400 hover:text-blue-600"
                    title="View Training"
                  >
                    <Eye className="w-5 h-5" />
                  </button>
                  <button 
                    onClick={() => handleUpdateTraining(category.id)} 
                    className="text-gray-400 hover:text-green-600"
                    title="Edit Training"
                  >
                    <Edit className="w-5 h-5" />
                  </button>
                  <button 
                    onClick={() => handleDeleteConfirmation(category)} 
                    className="text-gray-400 hover:text-red-600"
                    title="Delete Training"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Delete Confirmation Modal */}
      {deleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-xl">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p className="mb-6">
              Are you sure you want to delete the training "{deleteConfirmation.title}"?
            </p>
            <div className="flex justify-end space-x-2">
              <button 
                onClick={() => setDeleteConfirmation(null)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
              <button 
                onClick={handleDeleteTraining}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    </SidebarLayout>
  
  );
};

export default CategoriesPage;