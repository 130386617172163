// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';

// const CategoryPage = () => {
//   const [category, setCategory] = useState(null);
//   const [trainings, setTrainings] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const { categoryId } = useParams();

//   useEffect(() => {
//     const fetchCategoryDetails = async () => {
//       try {
//         // Fetch category details
//         const categoryResponse = await axios.get(`http://localhost:5000/api/categories/${categoryId}`);
//         setCategory(categoryResponse.data);

//         // Fetch trainings for this category
//         const trainingsResponse = await axios.get(`http://localhost:5000/api/training/category/${categoryId}`);
//         setTrainings(trainingsResponse.data);

//         setLoading(false);
//       } catch (err) {
//         setError('Failed to fetch category details');
//         setLoading(false);
//       }
//     };

//     fetchCategoryDetails();
//   }, [categoryId]);

//   if (loading) {
//     return (
//       <div className="container mx-auto px-4 py-8">
//         <div className="text-center text-gray-600">Loading...</div>
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div className="container mx-auto px-4 py-8">
//         <div className="text-center text-red-600">{error}</div>
//       </div>
//     );
//   }

//   return (
//     <div className="container mx-auto px-4 py-8">
//       <div className="mb-8">
//         <h1 className="text-3xl font-bold text-gray-900 mb-4">{category.name} Trainings</h1>
//         <p className="text-gray-600">{category.description}</p>
//       </div>

//       {trainings.length === 0 ? (
//         <div className="text-center text-gray-600">
//           No trainings available in this category.
//         </div>
//       ) : (
//         <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-6">
//           {trainings.map((training) => (
//             <div 
//               key={training.id} 
//               className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-xl transition-shadow"
//             >
//               <img 
//                 src={training.image || "/api/placeholder/400/250"} 
//                 alt={training.title} 
//                 className="w-full h-48 object-cover"
//               />
//               <div className="p-4">
//                 <h2 className="text-xl font-semibold text-gray-900 mb-2">
//                   {training.title}
//                 </h2>
//                 <p className="text-gray-600 mb-4 line-clamp-3">
//                   {training.description}
//                 </p>
//                 <div className="flex justify-between items-center">
//                   <span className="text-blue-600 font-medium">
//                     {training.price > 0 ? `$${training.price}` : 'Free'}
//                   </span>
//                   <button 
//                     className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors"
//                   >
//                     View Details
//                   </button>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default CategoryPage;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Clock, Users, BarChart3, Book } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import API_URL from '../../constants/Constants';

const CategoryPage = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState(null);
  const [trainings, setTrainings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { categoryId } = useParams();

  // Single feature image for all trainings
  const featureImage = "/images/33.jpg";

  const getLevelColor = (level) => {
    switch (level?.toLowerCase()) {
      case 'beginner':
        return 'bg-emerald-50 text-emerald-700 border-emerald-200';
      case 'intermediate':
        return 'bg-blue-50 text-blue-700 border-blue-200';
      case 'advanced':
        return 'bg-purple-50 text-purple-700 border-purple-200';
      default:
        return 'bg-gray-50 text-gray-700 border-gray-200';
    }
  };

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        // Fetch category details
        const categoryResponse = await fetch(`${API_URL}categories/${categoryId}`);
        const categoryData = await categoryResponse.json();
        setCategory(categoryData);

        // Fetch trainings for this category
        const trainingsResponse = await fetch(`${API_URL}training/category/${categoryId}`);
        const trainingsData = await trainingsResponse.json();
        setTrainings(trainingsData);

        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch category details:', err);
        setError('Failed to fetch category details');
        setLoading(false);
      }
    };

    fetchCategoryDetails();
  }, [categoryId]);

  if (loading) {
    return (
      <div className="flex items-center justify-center py-20">
        <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-10 px-4 bg-red-50 rounded-lg text-red-600">
        Error loading category details: {error}
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">{category.name} Trainings</h1>
        <p className="text-gray-600">{category.description}</p>
      </div>

      {trainings.length === 0 ? (
        <div className="text-center text-gray-600 py-10">
          No trainings available in this category.
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-10">
          {trainings.map((training) => (
            <div
              key={training.id}
              className="bg-white rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 hover:-translate-y-2 overflow-hidden"
            >
              {/* Training Image */}
              <div className="relative overflow-hidden">
                <img 
                  src={featureImage}
                  alt={training.title}
                  className="w-full h-48 object-cover"
                />
                <span className="absolute top-4 right-4 px-3 py-1 text-sm font-medium rounded-full border bg-indigo-50 text-indigo-700 border-indigo-200">
                  {category.name}
                </span>
              </div>

              {/* Training Content */}
              <div className="p-6">
                <div className="mb-4">
                  <h3 className="text-xl font-bold text-gray-900 hover:text-blue-600 transition-colors duration-200">
                    {training.title}
                  </h3>
                  <p className="mt-2 text-gray-600 text-sm line-clamp-2">
                    {training.description}
                  </p>
                </div>

                {/* Training Details */}
                <div className="grid grid-cols-2 gap-4 mb-6">
                  <div className="flex items-center text-gray-600 hover:text-blue-600 transition-colors">
                    <Clock className="w-4 h-4 mr-2" />
                    <span className="text-sm">{training.duration} days</span>
                  </div>
                  <div className="flex items-center text-gray-600 hover:text-blue-600 transition-colors">
                    <Users className="w-4 h-4 mr-2" />
                    <span className="text-sm">{training.instructor}</span>
                  </div>
                  <div className="flex items-center text-gray-600 hover:text-blue-600 transition-colors">
                    <BarChart3 className="w-4 h-4 mr-2" />
                    <span className={`text-sm px-2 py-0.5 rounded-full border ${getLevelColor(training.level)}`}>
                      {training.level}
                    </span>
                  </div>
                  <div className="flex items-center text-gray-600 hover:text-blue-600 transition-colors">
                    <Book className="w-4 h-4 mr-2" />
                    <span className="text-sm">{training.is_certified ? 'Certified' : 'Non-Certified'}</span>
                  </div>
                </div>

                {/* Price and Action */}
                <div className="flex items-center justify-between pt-4 border-t border-gray-100">
                  <div>
                    <span className="text-sm text-gray-500">Course Fee</span>
                    <p className="text-xl font-bold text-gray-900">{training.fee} $</p>
                  </div>
                  <button 
                    onClick={() => navigate(`/trainings/${training.id}`)}
                    className="inline-flex items-center px-6 py-2.5 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 hover:scale-105 active:scale-95"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoryPage;