import React, { useState } from 'react';
import { Book, Plus, Trash2 } from 'lucide-react';
import SidebarLayout from '../Layout/SidebarLayout';

const CreateCourse = () => {
  const [courseData, setCourseData] = useState({
    title: '',
    description: '',
    category: '',
    price: '',
    duration: '',
    prerequisites: []
  });

  const [newPrerequisite, setNewPrerequisite] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const addPrerequisite = () => {
    if (newPrerequisite.trim()) {
      setCourseData(prev => ({
        ...prev,
        prerequisites: [...prev.prerequisites, newPrerequisite.trim()]
      }));
      setNewPrerequisite('');
    }
  };

  const removePrerequisite = (index) => {
    setCourseData(prev => ({
      ...prev,
      prerequisites: prev.prerequisites.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the course data to your backend
    console.log('Course Data:', courseData);
    alert('Course Created Successfully!');
  };

  return (
    <SidebarLayout>
      <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-2xl mx-auto bg-white shadow-md rounded-lg p-8">
        <h1 className="text-3xl font-bold mb-6 flex items-center">
          <Book className="mr-4 text-blue-500" /> Create New Course
        </h1>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Course Title</label>
              <input
                type="text"
                name="title"
                required
                value={courseData.title}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
                placeholder="Enter course title"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Category</label>
              <select
                name="category"
                value={courseData.category}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              >
                <option value="">Select Category</option>
                <option value="web-development">Web Development</option>
                <option value="data-science">Data Science</option>
                <option value="digital-marketing">Digital Marketing</option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              name="description"
              value={courseData.description}
              onChange={handleInputChange}
              rows={4}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              placeholder="Provide a detailed course description"
            />
          </div>

          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Price ($)</label>
              <input
                type="number"
                name="price"
                value={courseData.price}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
                placeholder="Course price"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Duration</label>
              <select
                name="duration"
                value={courseData.duration}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              >
                <option value="">Select Duration</option>
                <option value="4-weeks">4 Weeks</option>
                <option value="8-weeks">8 Weeks</option>
                <option value="12-weeks">12 Weeks</option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Prerequisites</label>
            <div className="flex">
              <input
                type="text"
                value={newPrerequisite}
                onChange={(e) => setNewPrerequisite(e.target.value)}
                className="flex-grow border border-gray-300 rounded-l-md shadow-sm py-2 px-3"
                placeholder="Add a prerequisite"
              />
              <button
                type="button"
                onClick={addPrerequisite}
                className="bg-blue-500 text-white px-4 rounded-r-md hover:bg-blue-600 transition"
              >
                <Plus size={20} />
              </button>
            </div>
            
            {courseData.prerequisites.length > 0 && (
              <div className="mt-4 space-y-2">
                {courseData.prerequisites.map((prereq, index) => (
                  <div 
                    key={index} 
                    className="flex justify-between items-center bg-gray-100 px-3 py-2 rounded"
                  >
                    <span>{prereq}</span>
                    <button 
                      type="button" 
                      onClick={() => removePrerequisite(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <Trash2 size={18} />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="flex justify-end space-x-4 mt-6">
            <button 
              type="button" 
              className="bg-gray-200 text-gray-700 px-6 py-2 rounded hover:bg-gray-300 transition"
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 transition"
            >
              Create Course
            </button>
          </div>
        </form>
      </div>
    </div>
    </SidebarLayout>
    
  );
};

export default CreateCourse;