import React from 'react';
import { Medal, Target, Users, BookOpen } from 'lucide-react';

const AboutPage = () => {

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-16 px-4">
      <div className="max-w-6xl mx-auto">
        {/* Hero Section */}
        <section className="text-center mb-16">
          <h1 className="text-5xl font-extrabold text-gray-900 mb-4">
            About Our Organization
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            We are dedicated to empowering individuals through transformative learning experiences and innovative training solutions.
          </p>
        </section>

        {/* Mission and Values */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          <div className="bg-white p-8 rounded-xl shadow-lg">
            <div className="flex items-center mb-4">
              <Target className="w-8 h-8 text-blue-600 mr-4" />
              <h2 className="text-2xl font-bold text-gray-800">Our Mission</h2>
            </div>
            <p className="text-gray-600">
              To provide high-quality, accessible training that empowers individuals to achieve their professional goals and transform their careers.
            </p>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-lg">
            <div className="flex items-center mb-4">
              <Medal className="w-8 h-8 text-blue-600 mr-4" />
              <h2 className="text-2xl font-bold text-gray-800">Our Values</h2>
            </div>
            <ul className="space-y-2 text-gray-600">
              <li>• Excellence in Education</li>
              <li>• Continuous Learning</li>
              <li>• Professional Integrity</li>
              <li>• Student-Centered Approach</li>
            </ul>
          </div>
        </div>

        {/* Team Section */}
        <section>
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Our Leadership Team</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Meet the passionate professionals driving our organization's vision and commitment to excellence.
            </p>
          </div>

        </section>

        {/* Achievements */}
        <section className="mt-16 text-center bg-white rounded-xl p-12 shadow-lg">
          <div className="flex items-center justify-center mb-6">
            <BookOpen className="w-10 h-10 text-blue-600 mr-4" />
            <h2 className="text-3xl font-bold text-gray-900">Our Impact</h2>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            <div>
              <p className="text-5xl font-extrabold text-blue-600">500+</p>
              <p className="text-gray-600">Successful Trainings</p>
            </div>
            <div>
              <p className="text-5xl font-extrabold text-blue-600">1000+</p>
              <p className="text-gray-600">Trained Professionals</p>
            </div>
            <div>
              <p className="text-5xl font-extrabold text-blue-600">95%</p>
              <p className="text-gray-600">Student Satisfaction</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutPage;