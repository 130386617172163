import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import API_URL from '../../constants/Constants';
import { ChevronLeft, Edit } from 'lucide-react';
import SidebarLayout from '../Layout/SidebarLayout';

const ViewCategoryPage = () => {
    const [category, setCategory] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const response = await axios.get(`${API_URL}categories/${id}`);
                setCategory(response.data);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchCategory();
    }, [id]);

    const handleEdit = () => {
        navigate(`/admin/categories/update/${id}`);
    };

    const handleBack = () => {
        navigate('/admin/categories');
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error fetching category details</div>;
    if (!category) return <div>No category found</div>;

    return (
        <SidebarLayout>
            <div className="container mx-auto px-4 py-8">
                <div className="bg-white rounded-lg shadow-md p-6">
                    <div className="flex items-center justify-between mb-6">
                        <div className="flex items-center">
                            <button
                                onClick={handleBack}
                                className="mr-4 text-gray-600 hover:text-gray-900"
                            >
                                <ChevronLeft className="w-6 h-6" />
                            </button>
                            <h1 className="text-2xl font-bold">Category Details</h1>
                        </div>
                        <button
                            onClick={handleEdit}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        >
                            <Edit className="w-5 h-5" />
                            Edit
                        </button>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Category ID</label>
                            <p className="mt-1 block w-full py-2 px-3 border-b">{category.id}</p>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Category Name</label>
                            <p className="mt-1 block w-full py-2 px-3 border-b">{category.name}</p>
                        </div>
                        <div className="col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Description</label>
                            <p className="mt-1 block w-full py-2 px-3 border-b">
                                {category.description || 'No description provided'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </SidebarLayout>

    );
};

export default ViewCategoryPage;