// import React, { useState } from 'react';

// import { Star, X } from 'lucide-react';
// import API_URL from '../../constants/Constants';
// import ReviewSection from './ReviewSection';

// const ReviewModal = ({ trainingId, isOpen, onClose, onReviewSubmit }) => {
//   const [rating, setRating] = useState(0);
//   const [description, setDescription] = useState('');
//   const [email, setEmail] = useState('');
//   const [phone, setPhone] = useState('');
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [submitError, setSubmitError] = useState(null);

//   const renderStars = (currentRating, setCurrentRating) => {
//     return [1, 2, 3, 4, 5].map((star) => (
//       <Star
//         key={star}
//         onClick={() => setCurrentRating(star)}
//         className={`w-8 h-8 cursor-pointer transition-colors duration-200 ${
//           star <= currentRating
//             ? 'text-yellow-400 fill-yellow-400'
//             : 'text-gray-300 hover:text-yellow-300'
//         }`}
//       />
//     ));
//   };

//   const handleSubmitReview = async (e) => {
//     e.preventDefault();
//     setIsSubmitting(true);
//     setSubmitError(null);

//     // Basic validation
//     if (!email || !phone || rating === 0) {
//       setSubmitError('Please fill all required fields and select a rating');
//       setIsSubmitting(false);
//       return;
//     }

//     try {
//       const response = await fetch(`${API_URL}reviews`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           training_id: trainingId,
//           user_email: email,
//           user_phone: phone,
//           stars: rating,
//           description: description.trim() || null
//         })
//       });

//       if (!response.ok) {
//         throw new Error('Failed to submit review');
//       }

//       // Reset form after successful submission
//       setRating(0);
//       setDescription('');
//       setEmail('');
//       setPhone('');

//       // Optional: Trigger parent component's review submit handler
//       if (onReviewSubmit) {
//         onReviewSubmit();
//       }
//       onClose();
//     } catch (error) {
//       console.error('Review submission error:', error);
//       setSubmitError(error.message);
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//       <div className="bg-white rounded-xl shadow-2xl w-full max-w-2xl max-h-[90vh] overflow-y-auto relative">
//         {/* Close Button */}
//         <button
//           onClick={onClose}
//           className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 z-10"
//         >
//           <X className="w-6 h-6" />
//         </button>

//         {/* Existing Reviews Section */}
//         <div className="p-6">
//           <ReviewSection trainingId={trainingId} />
//         </div>

//         {/* Add Review Form */}
//         <div className="p-6 border-t">
//           <h3 className="text-2xl font-bold text-gray-800 mb-4">Add Your Review</h3>
//           <form onSubmit={handleSubmitReview} className="space-y-4">
//             <div>
//               <label className="block text-gray-700 mb-2">Rating</label>
//               <div className="flex items-center space-x-2">
//                 {renderStars(rating, setRating)}
//               </div>
//             </div>

//             <div>
//               <label className="block text-gray-700 mb-2">Email</label>
//               <input
//                 type="email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
//                 required
//               />
//             </div>

//             <div>
//               <label className="block text-gray-700 mb-2">Phone Number</label>
//               <input
//                 type="tel"
//                 value={phone}
//                 onChange={(e) => setPhone(e.target.value)}
//                 className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
//                 required
//               />
//             </div>

//             <div>
//               <label className="block text-gray-700 mb-2">Description (Optional)</label>
//               <textarea
//                 value={description}
//                 onChange={(e) => setDescription(e.target.value)}
//                 className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                 rows="4"
//                 placeholder="Share your experience with this training..."
//               />
//             </div>

//             {submitError && (
//               <div className="bg-red-50 text-red-700 p-3 rounded-lg">
//                 {submitError}
//               </div>
//             )}

//             <button
//               type="submit"
//               disabled={rating === 0 || isSubmitting}
//               className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
//             >
//               {isSubmitting ? 'Submitting...' : 'Submit Review'}
//             </button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReviewModal;

import React, { useState } from 'react';
import { Star, X } from 'lucide-react';
import API_URL from '../../constants/Constants';
import ReviewSection from './ReviewSection';

const ReviewModal = ({ trainingId, isOpen, onClose, onReviewSubmit }) => {
  const [rating, setRating] = useState(0);
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const renderStars = (currentRating, setCurrentRating) => {
    return [1, 2, 3, 4, 5].map((star) => (
      <Star
        key={star}
        onClick={() => setCurrentRating(star)}
        className={`w-8 h-8 cursor-pointer transition-colors duration-200 ${
          star <= currentRating
            ? 'text-yellow-400 fill-yellow-400'
            : 'text-gray-300 hover:text-yellow-300'
        }`}
      />
    ));
  };

  const handleSubmitReview = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError(null);

    // Basic validation
    if (!email || !phone || rating === 0) {
      setSubmitError('Please fill all required fields and select a rating');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}reviews`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          training_id: trainingId,
          user_email: email,
          user_phone: phone,
          stars: rating,
          description: description.trim() || null
        })
      });

      if (!response.ok) {
        throw new Error('Failed to submit review');
      }

      // Reset form after successful submission
      setRating(0);
      setDescription('');
      setEmail('');
      setPhone('');

      // Optional: Trigger parent component's review submit handler
      if (onReviewSubmit) {
        onReviewSubmit();
      }
      onClose();
    } catch (error) {
      console.error('Review submission error:', error);
      setSubmitError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-xl shadow-2xl w-full max-w-2xl max-h-[90vh] overflow-y-auto relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 z-10"
        >
          <X className="w-6 h-6" />
        </button>

        {/* Existing Reviews Section */}
        <div className="p-6">
          <ReviewSection trainingId={trainingId} />
        </div>

        {/* Add Review Form */}
        <div className="p-6 border-t">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">Add Your Review</h3>
          <form onSubmit={handleSubmitReview} className="space-y-4">
            <div>
              <label className="block text-gray-700 mb-2">Rating</label>
              <div className="flex items-center space-x-2">
                {renderStars(rating, setRating)}
              </div>
            </div>

            <div>
              <label className="block text-gray-700 mb-2">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 mb-2">Phone Number</label>
              <input
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 mb-2">Description (Optional)</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                rows="4"
                placeholder="Share your experience with this training..."
              />
            </div>

            {submitError && (
              <div className="bg-red-50 text-red-700 p-3 rounded-lg">
                {submitError}
              </div>
            )}

            <button
              type="submit"
              disabled={rating === 0 || isSubmitting}
              className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? 'Submitting...' : 'Submit Review'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReviewModal;