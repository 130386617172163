import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Calendar, User, Clock, Tag, MapPin, File, List } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import API_URL from '../../constants/Constants';
import SidebarLayout from '../Layout/SidebarLayout';

const ViewTrainingPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [training, setTraining] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTrainingDetails = async () => {
      try {
        // Fetch training details
        const trainingResponse = await axios.get(`${API_URL}training/${id}`);
        
        // Fetch training schedules
        const schedulesResponse = await fetch(`${API_URL}training-schedules/training/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        const schedulesData = await schedulesResponse.json();
        
        setTraining({
          ...trainingResponse.data,
          what_you_will_learn: trainingResponse.data.what_you_will_learn || ['']
        });
        
        setSchedules(Array.isArray(schedulesData) ? schedulesData : []);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching training details:', error);
        alert('Failed to fetch training details');
        setIsLoading(false);
      }
    };
  
    fetchTrainingDetails();
  }, [id]);

  const handleEdit = () => {
    navigate(`/trainings/update/${id}`);
  };

  if (isLoading) {
    return (
      <SidebarLayout>
        <div className="flex justify-center items-center h-full">
          <p>Loading training details...</p>
        </div>
      </SidebarLayout>
    );
  }

  if (!training) {
    return (
      <SidebarLayout>
        <div className="flex justify-center items-center h-full">
          <p>No training found.</p>
        </div>
      </SidebarLayout>
    );
  }

  return (
    <SidebarLayout>
      <div className="max-w-4xl m-8 mx-auto p-6 bg-white rounded-lg shadow-md">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">{training.title}</h2>
          <button 
            onClick={handleEdit}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
          >
            Edit Training
          </button>
        </div>

        <div className="grid md:grid-cols-2 gap-4 mb-6">
          <div className="flex items-center">
            <User className="mr-2 text-blue-500" />
            <span className="font-semibold">Training Provider:</span>
            <span className="ml-2">{training.instructor}</span>
          </div>
          <div className="flex items-center">
            <Clock className="mr-2 text-blue-500" />
            <span className="font-semibold">Duration:</span>
            <span className="ml-2">{training.duration} Days</span>
          </div>
          <div className="flex items-center">
            <Tag className="mr-2 text-blue-500" />
            <span className="font-semibold">Fee:</span>
            <span className="ml-2">{training.fee} $</span>
          </div>
          <div className="flex items-center">
            <MapPin className="mr-2 text-blue-500" />
            <span className="font-semibold">Address:</span>
            <span className="ml-2">{training.address}</span>
          </div>
        </div>

        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2">Description</h3>
          <p className="text-gray-700">{training.description}</p>
        </div>

        <div className="flex items-center mb-4">
          <File className="mr-2 text-blue-500" />
          <span className="font-semibold">Certification:</span>
          <span className="ml-2">
            {training.is_certified ? 'Available' : 'Not Available'}
          </span>
        </div>

        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2">Learning Outcomes</h3>
          <List className="inline-block mr-2 text-blue-500" />
          <ul className="list-disc list-inside">
            {training.what_you_will_learn.map((outcome, index) => (
              <li key={index} className="text-gray-700">{outcome}</li>
            ))}
          </ul>
        </div>

        <div className="border-t pt-4">
          <h3 className="text-lg font-semibold mb-4">Training Schedules</h3>
          {schedules.length > 0 ? (
            <div className="space-y-2">
              {schedules.map((schedule, index) => (
                <div 
                  key={index} 
                  className="flex items-center bg-gray-50 p-3 rounded-lg"
                >
                  <Calendar className="mr-2 text-blue-500" />
                  <div>
                    <span className="font-semibold">Start Date:</span>
                    <span className="ml-2 mr-4">{schedule.start_date}</span>
                    <span className="font-semibold">End Date:</span>
                    <span className="ml-2">{schedule.end_date}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-500">No schedules available</p>
          )}
        </div>
      </div>
    </SidebarLayout>
  );
};

export default ViewTrainingPage;