import React, { useState, useEffect } from 'react';
import { Users, TrendingUp, Calendar, Award } from 'lucide-react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';
import axios from 'axios';
import SidebarLayout from '../components/Layout/SidebarLayout';
import API_URL from '../constants/Constants';

const DashboardPage = () => {
  const [enrollments, setEnrollments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Mock data for other metrics
  const [dashboardMetrics, setDashboardMetrics] = useState({
    totalRevenue: 0,
    trainings: [],
    averageDuration: 0,
    certifiedCourses: 0,
    chartData: [
      { month: 'Jan', enrollments: 40 },
      { month: 'Feb', enrollments: 55 },
      { month: 'Mar', enrollments: 75 },
      { month: 'Apr', enrollments: 60 },
    ]
  });

  useEffect(() => {
    const fetchEnrollments = async () => {
      try {
        const response = await axios.get(`${API_URL}enrollments`);
        const data = response.data;

        setEnrollments(data);

        // Calculate metrics based on fetched data
        const totalRevenue = data.reduce((sum, enrollment) =>
          sum + parseFloat(enrollment.training_schedule.training.fee || 0), 0);

        const uniqueTrainings = [...new Set(data.map(e => e.training_schedule.training.title))];

        const certifiedCourses = data.filter(e =>
          e.training_schedule.training.is_certified
        ).length;

        setDashboardMetrics(prev => ({
          ...prev,
          totalRevenue,
          trainings: uniqueTrainings.map(title => ({ title })),
          averageDuration: Math.round(
            data.reduce((sum, e) => sum + (e.training_schedule.training.duration || 0), 0) /
            data.length
          ),
          certifiedCourses
        }));

        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchEnrollments();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-100 text-red-800 rounded">
        Error loading data: {error.message}
      </div>
    );
  }

  return (
    <SidebarLayout>
      <div className="p-4 sm:p-6 lg:p-8 space-y-6">
        {/* Key Metrics Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
          {/* Active Students Card */}
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow hover:shadow-md transition-shadow">
            <div className="flex justify-between items-start">
              <div className="flex-grow">
                <p className="text-xs sm:text-sm text-gray-600">Active Students</p>
                <h3 className="text-xl sm:text-2xl font-bold mt-1">
                  {enrollments.filter(e => e.status === 'active').length}
                </h3>
              </div>
              <div className="p-2 bg-blue-100 rounded ml-2">
                <Users className="w-4 h-4 sm:w-6 sm:h-6 text-blue-600" />
              </div>
            </div>
            <p className="text-xs sm:text-sm text-gray-500 mt-2">
              Total Enrollments: {enrollments.length}
            </p>
          </div>

          {/* Total Revenue Card */}
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow hover:shadow-md transition-shadow">
            <div className="flex justify-between items-start">
              <div className="flex-grow">
                <p className="text-xs sm:text-sm text-gray-600">Total Revenue</p>
                <h3 className="text-xl sm:text-2xl font-bold mt-1">
                  {dashboardMetrics.totalRevenue.toLocaleString()} $
                </h3>
              </div>
              <div className="p-2 bg-green-100 rounded ml-2">
                <TrendingUp className="w-4 h-4 sm:w-6 sm:h-6 text-green-600" />
              </div>
            </div>
            <p className="text-xs sm:text-sm text-gray-500 mt-2">
              Avg per course: {Math.round(dashboardMetrics.totalRevenue / dashboardMetrics.trainings.length).toLocaleString()} $
            </p>
          </div>

          {/* Average Duration Card */}
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow hover:shadow-md transition-shadow">
            <div className="flex justify-between items-start">
              <div className="flex-grow">
                <p className="text-xs sm:text-sm text-gray-600">Average Duration</p>
                <h3 className="text-xl sm:text-2xl font-bold mt-1">
                  {dashboardMetrics.averageDuration} days
                </h3>
              </div>
              <div className="p-2 bg-purple-100 rounded ml-2">
                <Calendar className="w-4 h-4 sm:w-6 sm:h-6 text-purple-600" />
              </div>
            </div>
            <p className="text-xs sm:text-sm text-gray-500 mt-2">
              Total Courses: {dashboardMetrics.trainings.length}
            </p>
          </div>

          {/* Certified Courses Card */}
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow hover:shadow-md transition-shadow">
            <div className="flex justify-between items-start">
              <div className="flex-grow">
                <p className="text-xs sm:text-sm text-gray-600">Certified Courses</p>
                <h3 className="text-xl sm:text-2xl font-bold mt-1">
                  {dashboardMetrics.certifiedCourses}
                </h3>
              </div>
              <div className="p-2 bg-yellow-100 rounded ml-2">
                <Award className="w-4 h-4 sm:w-6 sm:h-6 text-yellow-600" />
              </div>
            </div>
            <p className="text-xs sm:text-sm text-gray-500 mt-2">
              {Math.round((dashboardMetrics.certifiedCourses / dashboardMetrics.trainings.length) * 100)}% of total courses
            </p>
          </div>
        </div>

        {/* Charts and Recent Registrations Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
          {/* Monthly Enrollments Chart */}
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow">
            <h3 className="text-base sm:text-lg font-bold mb-4">Monthly Enrollments</h3>
            <div className="h-48 sm:h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={dashboardMetrics.chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="enrollments" fill="#3B82F6" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Recent Registrations */}
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow">
            <h3 className="text-base sm:text-lg font-bold mb-4">Recent Registrations</h3>
            <div className="space-y-3 sm:space-y-4">
              {enrollments.slice(0, 5).map(enrollment => (
                <div
                  key={enrollment.id}
                  className="flex items-center justify-between p-2 hover:bg-gray-50 rounded-lg transition"
                >
                  <div className="flex items-center">
                    <div className="w-6 h-6 sm:w-8 sm:h-8 bg-blue-100 rounded-full flex items-center justify-center">
                      <span className="text-blue-600 font-medium text-xs sm:text-sm">
                        {enrollment.fullname.charAt(0)}
                      </span>
                    </div>
                    <div className="ml-2 sm:ml-3">
                      <p className="text-xs sm:text-sm font-medium">{enrollment.fullname}</p>
                      <p className="text-[10px] sm:text-xs text-gray-500">
                        {enrollment.training_schedule.training.title}
                      </p>
                    </div>
                  </div>
                  <span
                    className={`px-1.5 py-0.5 sm:px-2 sm:py-1 text-[10px] sm:text-xs rounded-full ${enrollment.status === 'active'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-gray-100 text-gray-800'
                      }`}
                  >
                    {enrollment.status}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </SidebarLayout>

  );
};

export default DashboardPage;