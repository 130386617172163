// import React from 'react';
// import { Link } from 'react-router-dom';
// import { Helmet } from 'react-helmet-async'; // Added for SEO metadata
// import { Search, Book, Users, Award, Clock, Globe } from 'lucide-react';
// import TrainingList from '../components/Trainings/TrainingList';

// const HomePage = () => {
//   const stats = [
//     { label: 'Active Learners', value: '10,000+' },
//     { label: 'Training Completion Rate', value: '94%' },
//     { label: 'Expert Trainers', value: '100+' },
//     { label: 'Available Trainings', value: '500+' }
//   ];

//   return (
//     <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
//       {/* SEO Metadata */}
//       <Helmet>
//         <title>Expert-Led Online Training Platform | Transform Your Skills in Rwanda</title>
//         <meta
//           name="description"
//           content="Join our global learning community with 500+ expert-led trainings in Rwanda. Boost your skills, advance your career with flexible online courses. 10,000+ active learners, 94% completion rate."
//         />
//         <meta
//           name="keywords"
//           content="Training in Rwanda, online training Rwanda, professional development Rwanda, skill enhancement Rwanda, career training Rwanda, flexible learning Rwanda"
//         />
//         <link rel="canonical" href="https://traininginrwanda.com/" />
//         <meta property="og:title" content="Expert-Led Online Training Platform in Rwanda" />
//         <meta
//           property="og:description"
//           content="Transform your skills with our comprehensive online training platform in Rwanda. Learn from industry experts at your own pace."
//         />
//         <meta property="og:type" content="website" />
//         <meta property="og:url" content="https://traininginrwanda.com/" />
//         <meta name="twitter:card" content="summary_large_image" />
//         <meta name="twitter:title" content="Expert-Led Online Training Platform in Rwanda" />
//         <meta
//           name="twitter:description"
//           content="Join 10,000+ learners. 500+ expert-led trainings in Rwanda. 94% completion rate. Start your learning journey today!"
//         />
//       </Helmet>

//       {/* Rest of the component remains the same */}
//       <header className="relative bg-blue-600 text-white py-24">
//         <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-800 opacity-90"></div>
//         <div className="relative container mx-auto px-6">
//           <div className="max-w-3xl mx-auto text-center">
//             <h1 className="text-5xl font-bold mb-6 animate-fade-in">
//               Transform Your Future with Expert-Led Trainings in Rwanda
//             </h1>
//             <p className="text-xl mb-10 text-blue-100">
//               Join our global learning community and master new skills with industry-leading trainers in Rwanda.
//               Start your journey to success today.
//             </p>
//             <div className="flex flex-col sm:flex-row gap-4 justify-center">
//               <Link
//                 to="/trainings"
//                 className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-blue-50 transform hover:scale-105 transition shadow-lg"
//               >
//                 Explore Trainings in Rwanda
//               </Link>
//               <Link
//                 to="/trainings"
//                 className="bg-transparent border-2 border-white text-white px-8 py-4 rounded-lg font-semibold hover:bg-white/10 transition"
//               >
//                 Start Training in Rwanda
//               </Link>
//             </div>
//           </div>
//         </div>
//       </header>

//       <main>
//         {/* Stats Section */}
//         <section className="container mx-auto px-6 py-16">
//           <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
//             {stats.map((stat, index) => (
//               <div key={index} className="text-center p-6 bg-white rounded-xl shadow-md hover:shadow-lg transition">
//                 <p className="text-3xl font-bold text-blue-600 mb-2">{stat.value}</p>
//                 <p className="text-gray-600">{stat.label}</p>
//               </div>
//             ))}
//           </div>
//         </section>

//         {/* Featured Trainings in Rwanda */}
//         <section className="container mx-auto px-6 py-16">
//           <div className="text-center mb-12">
//             <h2 className="text-4xl font-bold mb-4">Featured Trainings in Rwanda</h2>
//             <p className="text-gray-600 max-w-2xl mx-auto">
//               Hand-picked training programs in Rwanda to get you started on your learning journey.
//               Explore our most popular and highly-rated offerings.
//             </p>
//           </div>
//           <TrainingList limit={3} />
//           <div className="text-center mt-8">
//             <Link
//               to="/trainings"
//               className="inline-block bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition"
//             >
//               View All Trainings
//             </Link>
//           </div>
//         </section>

//         {/* Features Grid */}
//         <section className="bg-white py-16">
//           <div className="container mx-auto px-6">
//             <div className="grid md:grid-cols-3 gap-12">
//               <div className="bg-blue-50 p-8 rounded-xl hover:shadow-xl transition">
//                 <Book className="w-12 h-12 text-blue-600 mb-6" />
//                 <h3 className="text-2xl font-semibold mb-4">Expert-Led Training in Rwanda</h3>
//                 <p className="text-gray-600">
//                   Learn from industry professionals with years of real-world experience in Rwanda.
//                   Get practical insights and stay updated with the latest trends.
//                 </p>
//               </div>
//               <div className="bg-blue-50 p-8 rounded-xl hover:shadow-xl transition">
//                 <Clock className="w-12 h-12 text-blue-600 mb-6" />
//                 <h3 className="text-2xl font-semibold mb-4">Flexible Learning in Rwanda</h3>
//                 <p className="text-gray-600">
//                   Train at your own pace with lifetime access to training content in Rwanda.
//                   Balance learning with your busy schedule.
//                 </p>
//               </div>
//               <div className="bg-blue-50 p-8 rounded-xl hover:shadow-xl transition">
//                 <Users className="w-12 h-12 text-blue-600 mb-6" />
//                 <h3 className="text-2xl font-semibold mb-4">Community Support in Rwanda</h3>
//                 <p className="text-gray-600">
//                   Join a vibrant community of learners in Rwanda. Collaborate, share insights,
//                   and grow together.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </section>

//         {/* CTA Section */}
//         <section className="bg-blue-900 text-white py-20">
//           <div className="container mx-auto px-6 text-center">
//             <h2 className="text-4xl font-bold mb-6">Ready to Start Training in Rwanda?</h2>
//             <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
//               Join thousands of successful learners in Rwanda who have transformed their careers
//               through our platform. Get started today and check out all trainings.
//             </p>
//             <Link
//               to="/trainings"
//               className="inline-block bg-white text-blue-900 px-8 py-4 rounded-lg font-semibold hover:bg-blue-50 transition"
//             >
//               All Trainings
//             </Link>
//           </div>
//         </section>
//       </main>
//     </div>
//   );
// };

// export default HomePage;

import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Search, Book, Users, Award, Clock, Globe } from 'lucide-react';
import TrainingList from '../components/Trainings/TrainingList';
import HeroImg from "./../assets/img/55.jpg"

const HomePage = () => {
  const stats = [
    { label: 'Active Learners', value: '10,000+', icon: Users },
    { label: 'Training Completion', value: '94%', icon: Award },
    { label: 'Expert Trainers', value: '100+', icon: Book },
    { label: 'Available Trainings', value: '500+', icon: Globe }
  ];

  return (
    <div className="bg-white text-gray-900">
      <Helmet>
        <title>Rwanda Learning Hub | Expert Online Training Platform</title>
        <meta
          name="description"
          content="Transform your skills with Rwanda's leading trainings platform. 500+ expert-led courses, 10,000+ learners, 94% completion rate."
        />
      </Helmet>

      <header className="relative bg-gradient-to-br from-[#1150ef] to-[#1a1e36] text-white py-44 overflow-hidden">
        {/* Background Image with Overlay */}
        <div
          className="absolute inset-0 bg-cover bg-center opacity-20 blur-sm"
          style={{
            backgroundImage: `url(${HeroImg})`,
            backgroundBlendMode: 'overlay'
          }}
        ></div>

        <div className="container mx-auto px-6 text-center relative z-10">
          <h1 className="text-5xl font-extrabold mb-6 leading-tight text-white">
            Elevate Your Skills, Transform Your Career
          </h1>
          <p className="text-xl opacity-80 max-w-2xl mx-auto mb-10">
            Join Rwanda's premier trainings platform. Expert-led trainings designed to accelerate your professional growth.
          </p>
     

          <div className="flex justify-center space-x-4">
            <Link
              to="/trainings"
              className="px-8 py-4 w-1/4 bg-[#3DF28E] text-[#1150ef] rounded-full font-semibold shadow-lg transition-all transform hover:scale-105 hover:bg-[#32e37c] hover:text-[#0e47d9]"
            >
              Explore Trainings
            </Link>
          </div>


        </div>
      </header>

      <section className="container mx-auto px-6 py-16 bg-white">
        <div className="grid md:grid-cols-4 gap-6">
          {stats.map((stat, index) => {
            const Icon = stat.icon;
            return (
              <div
                key={index}
                className="bg-[#1150ef]/5 p-6 rounded-xl text-center hover:shadow-xl transition-all group"
              >
                <div className="flex justify-center mb-4">
                  <Icon className="w-10 h-10 text-[#1150ef] group-hover:scale-110 transition-transform" />
                </div>
                <div className="text-3xl font-bold text-[#1150ef] mb-2">{stat.value}</div>
                <div className="text-gray-600">{stat.label}</div>
              </div>
            );
          })}
        </div>
      </section>

      <section className="bg-gray-50">
        <div className="container max-w-7lg mx-auto px-6 py-16">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold mb-4 text-gray-800">Featured Training Programs</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Curated, expert-led courses to kickstart your professional development journey.
            </p>
          </div>
          <TrainingList limit={3} />
          <div className="text-center mt-10">
            <Link
              to="/trainings"
              className="px-8 py-4 bg-[#1150ef] text-white rounded-full hover:bg-[#1150ef]/90 transition-colors"
            >
              View All Trainings
            </Link>
          </div>
        </div>
      </section>

      <section className="bg-white py-16">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: Book,
                title: 'Expert-Driven Learning',
                description: 'Learn from industry professionals with real-world insights and cutting-edge expertise.'
              },
              {
                icon: Clock,
                title: 'Flexible Learning',
                description: 'Study at your own pace with lifetime access to comprehensive course materials.'
              },
              {
                icon: Users,
                title: 'Collaborative Community',
                description: 'Connect with peers, share knowledge, and grow together in a supportive learning environment.'
              }
            ].map((feature, index) => (
              <div
                key={index}
                className="bg-white p-8 rounded-xl border border-gray-100 hover:shadow-xl transition-all group"
              >
                <div className="flex justify-center mb-6">
                  <feature.icon className="w-12 h-12 text-[#3DF28E] group-hover:scale-110 transition-transform" />
                </div>
                <h3 className="text-2xl font-semibold mb-4 text-center text-gray-800">{feature.title}</h3>
                <p className="text-gray-600 text-center">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-[#1150ef]/10 py-20">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold mb-6 text-gray-800">Your Learning Journey Starts Here</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-10">
            Join thousands of successful learners who have transformed their careers through our platform.
          </p>
          <Link
            to="/trainings"
            className="px-10 py-4 bg-[#1150ef] text-white rounded-full font-semibold hover:bg-[#1150ef]/90 transition-colors"
          >
            Explore All Trainings
          </Link>
        </div>
      </section>
    </div>
  );
};

export default HomePage;