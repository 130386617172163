import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import API_URL from '../../constants/Constants';

const ReviewSection = ({ trainingId }) => {
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        // Fetch reviews for the specific training
        const reviewsResponse = await fetch(`${API_URL}reviews/training/${trainingId}`);
        if (!reviewsResponse.ok) {
          throw new Error('Failed to fetch reviews');
        }
        const reviewsData = await reviewsResponse.json();
        setReviews(reviewsData);

        // Fetch average rating
        const ratingResponse = await fetch(`${API_URL}reviews/training/${trainingId}/rating`);
        if (!ratingResponse.ok) {
          throw new Error('Failed to fetch rating');
        }
        const ratingData = await ratingResponse.json();
        setAverageRating(ratingData.averageRating);
        setReviewCount(ratingData.reviewCount);

        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchReviews();
  }, [trainingId]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-10">
        <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 text-red-700 p-4 rounded-lg">
        Error loading reviews: {error}
      </div>
    );
  }

  // Render average rating stars
  const renderStars = (rating) => {
    return [1, 2, 3, 4, 5].map((star) => (
      <Star
        key={star}
        className={`w-5 h-5 ${
          star <= Math.round(rating) 
            ? 'text-yellow-400 fill-yellow-400' 
            : 'text-gray-300'
        }`}
      />
    ));
  };

  return (
    <div className="bg-white rounded-xl p-6 mt-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl font-bold text-gray-800">Reviews</h3>
        <div className="flex items-center">
          <div className="flex mr-2">
            {renderStars(averageRating)}
          </div>
          <span className="text-gray-600">
            {averageRating.toFixed(1)} ({reviewCount} reviews)
          </span>
        </div>
      </div>

      {reviews.length === 0 ? (
        <p className="text-gray-600 text-center">No reviews yet</p>
      ) : (
        <div className="space-y-4">
          {reviews.map((review) => (
            <div 
              key={review.id} 
              className="border-b pb-4 last:border-b-0"
            >
              <div className="flex items-center mb-2">
                <div className="flex mr-2">
                  {renderStars(review.stars)}
                </div>
                <span className="text-gray-600 text-sm">
                  {review.user_email}
                </span>
              </div>
              {review.description && (
                <p className="text-gray-700">{review.description}</p>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ReviewSection;