import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft, User, Mail, Phone, MapPin, Calendar } from 'lucide-react';
import axios from 'axios';
import API_URL from '../../constants/Constants';

const EnrollmentForm = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [training, setTraining] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    phone: '',
    address: '',
  });

  useEffect(() => {
    // Check if training and schedule are passed from previous page
    if (location.state?.training && location.state?.schedule) {
      setTraining(location.state.training);
      setSchedule(location.state.schedule);
    } else {
      // Fallback: Redirect if no state is passed
      navigate('/trainings');
    }
  }, [location.state, navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Prepare enrollment data
      const enrollmentData = {
        ...formData,
        training_schedule_id: schedule.id
      };

      // Submit enrollment to API
      const response = await axios.post(`${API_URL}enrollments`, enrollmentData);

      // Navigate to confirmation page
      navigate('/enrollment/confirmation', {
        state: {
          training: training,
          schedule: schedule,
          enrollmentDetails: {
            ...formData,
            enrollmentId: response.data.id
          }
        }
      });
    } catch (error) {
      console.error('Enrollment submission error:', error);
      alert('Failed to submit enrollment. Please try again.');
    }
  };

  if (!training || !schedule) {
    return <div className="text-center py-10">Loading enrollment details...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      {/* Back Button */}
      <button 
        onClick={() => navigate(-1)}
        className="flex items-center text-gray-600 hover:text-gray-900 mb-8 mx-auto max-w-2xl"
      >
        <ArrowLeft className="w-4 h-4 mr-2" />
        Back to Course
      </button>

      <div className="max-w-2xl mx-auto">
        {/* Header */}
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Registration Form</h1>
          <p className="mt-2 text-lg text-gray-600">{training.title}</p>
        </div>

        {/* Main Form */}
        <div className="bg-white shadow-lg rounded-2xl overflow-hidden">
          {/* Course Info Banner */}
          <div className="bg-blue-600 text-white px-6 py-4 flex justify-between items-center">
            <div>
              <p className="text-sm font-medium">Course Fee</p>
              <p className="text-2xl font-bold">{training.fee} $</p>
            </div>
            <div className="flex items-center">
              <Calendar className="w-5 h-5 mr-2" />
              <span>{formatDate(schedule.start_date)} - {formatDate(schedule.end_date)}</span>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="p-8 space-y-6">
            <div>
              <label className="flex items-center text-gray-700 text-sm font-semibold mb-2">
                <User className="w-4 h-4 mr-2" />
                Full Name
              </label>
              <input
                type="text"
                name="fullname"
                value={formData.fullname}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                placeholder="Enter your full name"
              />
            </div>

            <div>
              <label className="flex items-center text-gray-700 text-sm font-semibold mb-2">
                <Mail className="w-4 h-4 mr-2" />
                Email Address
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                placeholder="Enter your email"
              />
            </div>

            <div>
              <label className="flex items-center text-gray-700 text-sm font-semibold mb-2">
                <Phone className="w-4 h-4 mr-2" />
                Phone Number
              </label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                placeholder="Enter your phone number"
              />
            </div>

            <div>
              <label className="flex items-center text-gray-700 text-sm font-semibold mb-2">
                <MapPin className="w-4 h-4 mr-2" />
                Address
              </label>
              <textarea
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
                rows="3"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                placeholder="Enter your address"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors font-semibold"
            >
              Complete Registration
            </button>
          </form>
        </div>

        {/* Additional Info */}
        <p className="text-center text-gray-500 text-sm mt-6">
          By registering, you agree to our Terms of Service and Privacy Policy
        </p>
      </div>
    </div>
  );
};

export default EnrollmentForm;