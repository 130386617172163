// import React, { useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';

// const Navbar = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
//   const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
//   const location = useLocation();

//   // Dummy auth state - replace with your actual auth logic
//   const isAuthenticated = false;
//   const userAvatar = "/api/placeholder/32/32";

//   const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
//   const toggleProfileDropdown = () => setIsProfileDropdownOpen(!isProfileDropdownOpen);
//   const toggleCategoryDropdown = () => setIsCategoryDropdownOpen(!isCategoryDropdownOpen);

//   // Check if the current path matches the link
//   const isActivePath = (path) => location.pathname === path;

//   return (
//     <nav className="bg-white shadow-md">
//       <div className="container mx-auto px-4">
//         <div className="flex justify-between items-center h-16">
//           {/* Logo */}
//           <Link to="/" className="flex items-center space-x-3">
//             <svg className="w-8 h-8 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.168.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.168.477 4.5 1.253v13C19.668 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
//             </svg>
//             <span className="font-bold text-xl text-gray-900">Training In Rwanda</span>
//           </Link>

//           {/* Desktop Navigation */}
//           <div className="hidden md:flex items-center space-x-8">
//             <Link 
//               to="/trainings"
//               className={`text-gray-700 hover:text-blue-600 transition-colors ${isActivePath('/courses') ? 'text-blue-600' : ''}`}
//             >
//               All Trainings
//             </Link>

//             <Link 
//               to="/about"
//               className={`text-gray-700 hover:text-blue-600 transition-colors ${isActivePath('/about') ? 'text-blue-600' : ''}`}
//             >
//               About
//             </Link>
            
//             <Link 
//               to="/contact"
//               className={`text-gray-700 hover:text-blue-600 transition-colors ${isActivePath('/contact') ? 'text-blue-600' : ''}`}
//             >
//               Contact
//             </Link>

//             {/* Search Bar */}
//             <div className="relative">
//               <input
//                 type="text"
//                 placeholder="Search Trainings..."
//                 className="w-64 px-4 py-1 text-gray-700 bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:bg-white"
//               />
//               <button className="absolute right-3 top-1/2 transform -translate-y-1/2">
//                 <svg className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
//                 </svg>
//               </button>
//             </div>

//             {/* Auth Buttons / Profile */}
//             {isAuthenticated ? (
//               <div className="relative">
//                 <button
//                   onClick={toggleProfileDropdown}
//                   className="flex items-center space-x-2"
//                 >
//                   <img
//                     src={userAvatar}
//                     alt="Profile"
//                     className="w-8 h-8 rounded-full"
//                   />
//                   <svg className="w-4 h-4 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
//                   </svg>
//                 </button>

//                 {isProfileDropdownOpen && (
//                   <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
//                     <Link to="/profile" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">My Profile</Link>
//                     <Link to="/dashboard" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">Dashboard</Link>
//                     <Link to="/settings" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">Settings</Link>
//                     <hr className="my-1" />
//                     <button className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100">
//                       Sign Out
//                     </button>
//                   </div>
//                 )}
//               </div>
//             ) : (
//               <div className="flex items-center space-x-4">
//                 <Link
//                   to="/login"
//                   className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors"
//                 >
//                   Login
//                 </Link>
//               </div>
//             )}
//           </div>

//           {/* Mobile Menu Button */}
//           <button
//             onClick={toggleMenu}
//             className="md:hidden rounded-md p-2 text-gray-700 hover:bg-gray-100 focus:outline-none"
//           >
//             <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
//             </svg>
//           </button>
//         </div>

//         {/* Mobile Menu */}
//         {isMenuOpen && (
//           <div className="md:hidden py-4">
//             <div className="flex flex-col space-y-4">
//               <div className="px-4 mb-2">
//                 <input
//                   type="text"
//                   placeholder="Search Trainings..."
//                   className="w-full px-4 py-2 text-gray-700 bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:bg-white"
//                 />
//               </div>
//               <Link to="/trainings" className="px-4 py-2 text-gray-700 hover:bg-gray-100">All Trainings</Link>
           
//               <Link to="/about" className="px-4 py-2 text-gray-700 hover:bg-gray-100">About</Link>
//               <Link to="/contact" className="px-4 py-2 text-gray-700 hover:bg-gray-100">Contact</Link>
              
//               {!isAuthenticated && (
//                 <div className="px-4 py-2 space-y-2">
//                   <Link
//                     to="/login"
//                     className="block w-full text-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
//                   >
//                     Login
//                   </Link>
                 
//                 </div>
//               )}
//             </div>
//           </div>
//         )}
//       </div>
//     </nav>
//   );
// };

// export default Navbar;

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../constants/Constants';
import logo from "./../../assets/img/logo.png"

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const location = useLocation();

  // Dummy auth state - replace with your actual auth logic
  const isAuthenticated = false;

  // Fetch categories on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_URL}categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleCategoryDropdown = () => setIsCategoryDropdownOpen(!isCategoryDropdownOpen);

  // Check if the current path matches the link
  const isActivePath = (path) => location.pathname === path;

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-28">
          {/* Logo */}
          <Link to="/" className="flex items-center">
            <img
              src={logo}
              alt="Logo"
              className="h-28 w-auto p-1"
            />
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {/* Categories Dropdown */}
            <div className="relative">
              <button
                onClick={toggleCategoryDropdown}
                className={`text-gray-700 hover:text-[#1150ef] transition-colors flex items-center ${isActivePath('/categories') ? 'text-[#1150ef]' : ''}`}
              >
                Categories
                <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>

              {isCategoryDropdownOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
                  {categories.map((category) => (
                    <Link 
                      key={category.id} 
                      to={`/category/${category.id}`} 
                      className="block px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10"
                      onClick={() => setIsCategoryDropdownOpen(false)}
                    >
                      {category.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            <Link 
              to="/trainings"
              className={`text-gray-700 hover:text-[#1150ef] transition-colors ${isActivePath('/courses') ? 'text-[#1150ef]' : ''}`}
            >
              All Trainings
            </Link>

            <Link 
              to="/about"
              className={`text-gray-700 hover:text-[#1150ef] transition-colors ${isActivePath('/about') ? 'text-[#1150ef]' : ''}`}
            >
              About
            </Link>
            
            <Link 
              to="/contact"
              className={`text-gray-700 hover:text-[#1150ef] transition-colors ${isActivePath('/contact') ? 'text-[#1150ef]' : ''}`}
            >
              Contact
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={toggleMenu}
            className="md:hidden rounded-md p-2 text-gray-700 hover:bg-[#3DF28E]/10 focus:outline-none"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
            </svg>
          </button>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden py-4">
            <div className="flex flex-col space-y-4">
              {/* Mobile Search */}
              <div className="px-4 mb-2">
                <input
                  type="text"
                  placeholder="Search Trainings..."
                  className="w-full px-4 py-2 text-gray-700 bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-[#1150ef] focus:bg-white"
                />
              </div>

              {/* Categories Dropdown for Mobile */}
              <div>
                <button
                  onClick={toggleCategoryDropdown}
                  className="w-full text-left px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10 flex justify-between items-center"
                >
                  Categories
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </button>

                {isCategoryDropdownOpen && (
                  <div className="pl-4">
                    {categories.map((category) => (
                      <Link 
                        key={category.id} 
                        to={`/category/${category.id}`} 
                        className="block px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10"
                        onClick={() => {
                          setIsCategoryDropdownOpen(false);
                          setIsMenuOpen(false);
                        }}
                      >
                        {category.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>

              <Link to="/trainings" className="px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10">All Trainings</Link>
              <Link to="/about" className="px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10">About</Link>
              <Link to="/contact" className="px-4 py-2 text-gray-700 hover:bg-[#3DF28E]/10">Contact</Link>
              
              {!isAuthenticated && (
                <div className="px-4 py-2 space-y-2">
                  <Link
                    to="/login"
                    className="block w-full text-center px-4 py-2 bg-[#1150ef] text-white rounded-md hover:bg-[#0e47d9]"
                  >
                    Login
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;