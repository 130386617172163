import React, { useState } from 'react';
import { 
  LayoutGrid, 
  Book, 
  Users, 
  Settings, 
  LogOut, 
  Menu, 
  X,
  Package 
} from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';

const SidebarLayout = ({ children }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    // Remove the authentication token from localStorage
    localStorage.removeItem('token');
    
    // Redirect to login page
    navigate('/login');
  };

  const NavigationItems = ({ mobile = false }) => {
    // Determine active route based on current location
    const isActive = (path) => location.pathname.startsWith(path);

    return (
      <div className={`space-y-1 ${mobile ? 'p-4' : ''}`}>
        <button
          onClick={() => navigate('/dashboard')}
          className={`flex items-center w-full px-4 py-2 text-sm rounded-lg ${
            isActive('/dashboard')
              ? 'bg-blue-50 text-blue-600'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
        >
          <LayoutGrid className="w-5 h-5 mr-3" />
          Dashboard
        </button>

        <button
          onClick={() => navigate('/admin/trainings')}
          className={`flex items-center w-full px-4 py-2 text-sm rounded-lg ${
            isActive('/admin/trainings')
              ? 'bg-blue-50 text-blue-600'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
        >
          <Book className="w-5 h-5 mr-3" />
          Trainings
        </button>

        <button
          onClick={() => navigate('/admin/categories')}
          className={`flex items-center w-full px-4 py-2 text-sm rounded-lg ${
            isActive('/admin/categories')
              ? 'bg-blue-50 text-blue-600'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
        >
          <Package className="w-5 h-5 mr-3" />
          Categories
        </button>

        <button
          onClick={() => navigate('/admin/students')}
          className={`flex items-center w-full px-4 py-2 text-sm rounded-lg ${
            isActive('/admin/students')
              ? 'bg-blue-50 text-blue-600'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
        >
          <Users className="w-5 h-5 mr-3" />
          Students
        </button>
      </div>
    );
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Desktop Sidebar */}
      <div className="w-64 bg-white shadow-lg hidden md:block">
        <div className="h-full flex flex-col">
          <div className="p-6 border-b">
            <h1 className="text-2xl font-bold text-gray-800">Admin Dashboard</h1>
          </div>

          <nav className="flex-1 p-4">
            <NavigationItems />
          </nav>

          <div className="p-4 border-t mt-auto">
            <button 
              onClick={handleLogout}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-600 hover:bg-gray-50 rounded-lg mt-2"
            >
              <LogOut className="w-5 h-5 mr-3" />
              Logout
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden">
          <div className="fixed inset-y-0 left-0 w-64 bg-white shadow-lg">
            <div className="h-full flex flex-col">
              <div className="p-6 border-b flex justify-between items-center">
                <h1 className="text-xl font-bold text-gray-800">Admin Dashboard</h1>
                <button
                  onClick={() => setIsMobileMenuOpen(false)}
                  className="p-2 rounded-lg hover:bg-gray-100"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>

              <nav className="flex-1">
                <NavigationItems mobile />
              </nav>

              <div className="p-4 border-t">
                <button className="flex items-center w-full px-4 py-2 text-sm text-gray-600 hover:bg-gray-50 rounded-lg">
                  <Settings className="w-5 h-5 mr-3" />
                  Settings
                </button>
                <button 
                  onClick={handleLogout}
                  className="flex items-center w-full px-4 py-2 text-sm text-gray-600 hover:bg-gray-50 rounded-lg mt-2"
                >
                  <LogOut className="w-5 h-5 mr-3" />
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Mobile Header */}
        <header className="bg-white shadow-sm md:hidden">
          <div className="flex items-center justify-between p-4">
            <button 
              onClick={() => setIsMobileMenuOpen(true)}
              className="p-2 rounded-lg hover:bg-gray-100"
            >
              <Menu className="w-5 h-5" />
            </button>
            <h1 className="text-xl font-bold text-gray-800">Training In Rwanda</h1>
            <button className="p-2 rounded-lg hover:bg-gray-100">
              <Settings className="w-5 h-5" />
            </button>
          </div>
        </header>

        {/* Main Content Area */}
        <main className="flex-1 overflow-y-auto p-6">
          {children}
        </main>
      </div>
    </div>
  );
};

export default SidebarLayout;