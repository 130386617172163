
// import React, { useState, useEffect } from 'react';
// import { Clock, Users, BarChart3, Book, MessageSquare, Star } from 'lucide-react';
// import { useNavigate } from 'react-router-dom';
// import API_URL from '../../constants/Constants';
// import ReviewModal from '../Review/ReviewModal';

// const TrainingList = ({ limit }) => {
//   const navigate = useNavigate();
//   const [trainings, setTrainings] = useState([]);
//   const [trainingReviews, setTrainingReviews] = useState({});
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedTrainingId, setSelectedTrainingId] = useState(null);
//   const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

//   // Array of feature images
//   const featureImages = [
//     '/images/1.jpg',
//     '/images/22.jpg',
//     '/images/33.jpg',
//     '/images/55.jpg',
//     '/images/66.jpg',
//     '/images/77.jpg'
//   ];

//   // Randomly assign feature images to trainings
//   const [trainingImages, setTrainingImages] = useState({});

//   useEffect(() => {
//     const fetchTrainings = async () => {
//       try {
//         const trainingsResponse = await fetch(`${API_URL}training/`);
//         if (!trainingsResponse.ok) {
//           throw new Error('Failed to fetch trainings');
//         }

//         const trainingsData = await trainingsResponse.json();
//         setTrainings(Array.isArray(trainingsData) ? trainingsData : []);

//         // Randomly assign feature images to trainings
//         const imageAssignments = {};
//         trainingsData.forEach(training => {
//           imageAssignments[training.id] =
//             featureImages[Math.floor(Math.random() * featureImages.length)];
//         });
//         setTrainingImages(imageAssignments);

//         // Start loading reviews in parallel after trainings are fetched
//         const reviewPromises = trainingsData.map(training =>
//           fetch(`${API_URL}reviews/training/${training.id}`)
//             .then(response => response.json())
//             .then(reviews => ({ trainingId: training.id, reviews }))
//         );

//         const reviewsResults = await Promise.all(reviewPromises);

//         // Create a mapping of training IDs to their reviews
//         const reviewsByTraining = reviewsResults.reduce((acc, { trainingId, reviews }) => {
//           acc[trainingId] = reviews;
//           return acc;
//         }, {});

//         setTrainingReviews(reviewsByTraining);
//         setIsLoading(false);
//       } catch (error) {
//         console.error('Fetching data failed:', error);
//         setError(error.message);
//         setIsLoading(false);
//       }
//     };

//     fetchTrainings();
//   },[]);

//   const calculateReviewStats = (trainingId) => {
//     const reviews = trainingReviews[trainingId] || [];
//     if (reviews.length === 0) {
//       return { averageRating: 0, reviewCount: 0 };
//     }

//     const totalStars = reviews.reduce((sum, review) => sum + review.stars, 0);
//     const averageRating = (totalStars / reviews.length).toFixed(1);

//     return {
//       averageRating: parseFloat(averageRating),
//       reviewCount: reviews.length
//     };
//   };

//   const getLevelColor = (level) => {
//     switch (level?.toLowerCase()) {
//       case 'beginner':
//         return 'bg-[#3DF28E]/10 text-[#3DF28E] border-[#3DF28E]/30';
//       case 'intermediate':
//         return 'bg-[#1150ef]/10 text-[#1150ef] border-[#1150ef]/30';
//       case 'advanced':
//         return 'bg-purple-50 text-purple-700 border-purple-200';
//       default:
//         return 'bg-gray-50 text-gray-700 border-gray-200';
//     }
//   };

//   const getCategoryColor = () => {
//     return 'bg-[#1150ef]/10 text-[#1150ef] border-[#1150ef]/30';
//   };

//   const renderRatingStars = (rating) => {
//     return [1, 2, 3, 4, 5].map((star) => (
//       <Star
//         key={star}
//         className={`w-4 h-4 ${star <= Math.round(rating)
//           ? 'text-[#1150ef] fill-[#1150ef]'
//           : 'text-gray-300'
//           }`}
//       />
//     ));
//   };

//   const openReviewModal = (trainingId) => {
//     setSelectedTrainingId(trainingId);
//     setIsReviewModalOpen(true);
//   };

//   const closeReviewModal = () => {
//     setIsReviewModalOpen(false);
//     setSelectedTrainingId(null);
//   };


//   if (isLoading) {
//     return (
//       <div className="flex items-center justify-center py-20">
//         <div className="w-12 h-12 border-4 border-[#1150ef] border-t-transparent rounded-full animate-spin" />
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div className="text-center py-10 px-4 bg-red-50 rounded-lg text-red-600">
//         Error loading trainings: {error}
//       </div>
//     );
//   }

//   if (!trainings?.length) {
//     return (
//       <div className="text-center py-10 px-4">
//         <p className="text-gray-600">No trainings available at the moment.</p>
//       </div>
//     );
//   }

//   const displayedTrainings = limit ? trainings.slice(0, limit) : trainings;

//   return (
//     <>
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-10">
//         {displayedTrainings.map((training) => {
//           const { averageRating, reviewCount } = calculateReviewStats(training.id);
//           return (
//             <div
//               key={training.id}
//               className="bg-white rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 hover:-translate-y-2 overflow-hidden"
//             >
//               {/* Training Image */}
//               <div className="relative overflow-hidden">
//                 <img
//                   src={trainingImages[training.id] || featureImages[0]}
//                   alt={training.title}
//                   className="w-full h-48 object-cover"
//                 />
//                 <span className={`absolute top-4 right-4 px-3 py-1 text-sm font-medium rounded-full border ${getCategoryColor()}`}>
//                   Training
//                 </span>
//               </div>

//               {/* Rest of the component remains the same */}
//               <div className="p-6">
//                 <div className="mb-4">
//                   <h3 className="text-xl font-bold text-gray-900 hover:text-[#1150ef] transition-colors duration-200">
//                     {training.title}
//                   </h3>
//                   <p className="mt-2 text-gray-600 text-sm line-clamp-2">
//                     {training.description}
//                   </p>
//                 </div>

//                 {/* Training Details */}
//                 <div className="grid grid-cols-2 gap-4 mb-6">
//                   <div className="flex items-center text-gray-600 hover:text-blue-600 transition-colors">
//                     <Clock className="w-4 h-4 mr-2" />
//                     <span className="text-sm">{training.duration} days</span>
//                   </div>
//                   <div className="flex items-center text-gray-600 hover:text-blue-600 transition-colors">
//                     <Users className="w-4 h-4 mr-2" />
//                     <span className="text-sm">{training.instructor}</span>
//                   </div>
//                   <div className="flex items-center text-gray-600 hover:text-blue-600 transition-colors">
//                     <BarChart3 className="w-4 h-4 mr-2" />
//                     <span className={`text-sm px-2 py-0.5 rounded-full border ${getLevelColor(training.level)}`}>
//                       {training.level}
//                     </span>
//                   </div>
//                   <div className="flex items-center text-gray-600 hover:text-blue-600 transition-colors">
//                     <Book className="w-4 h-4 mr-2" />
//                     <span className="text-sm">{training.is_certified ? 'Certified' : 'Non-Certified'}</span>
//                   </div>
//                 </div>

//                 {/* Reviews Section */}
//                 <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow-md border border-gray-200 mb-4">
//                   <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between w-full">
//                     {/* Review Title */}
//                     <span className="font-semibold text-lg text-gray-800">Review</span>

//                     {/* Rating stars and Average Rating */}
//                     <div className="flex items-center mt-2 sm:mt-0 space-x-2">
//                       <div className="flex">{renderRatingStars(averageRating)}</div>
//                       <span className="text-sm text-gray-500">
//                         {averageRating} ({reviewCount} reviews)
//                       </span>
//                     </div>
//                   </div>
//                 </div>

//                 {/* Price and Action */}
//                 <div className="flex items-center justify-between pt-4 border-t border-gray-100">
//                   <div>
//                     <span className="text-sm text-gray-500">Course Fee</span>
//                     <p className="text-xl font-bold text-gray-900">{training.fee} $</p>
//                   </div>
//                   <div className="flex flex-col items-center space-y-2">
//                     <button
//                       onClick={() => navigate(`/trainings/${training.id}`)}
//                       className="inline-flex items-center px-4 py-2.5 bg-[#1150ef] text-white rounded-lg hover:bg-[#1150ef]/90 transition-colors duration-200 hover:scale-105 active:scale-95"
//                     >
//                       Learn More
//                     </button>
//                     <button
//                       onClick={() => openReviewModal(training.id)}
//                       className="inline-flex items-center px-4 py-2.5 bg-[#3DF28E] text-gray-900 rounded-lg hover:bg-[#3DF28E]/90 transition-colors duration-200 hover:scale-105 active:scale-95"
//                     >
//                       <MessageSquare className="w-4 h-4 mr-2" /> Review
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           );
//         })}
//       </div>

//       {/* Review Modal */}
//       {isReviewModalOpen && (
//         <ReviewModal
//           trainingId={selectedTrainingId}
//           isOpen={isReviewModalOpen}
//           onClose={closeReviewModal}
//           onReviewSubmit={() => {/* Optional: add any refresh logic */ }}
//         />
//       )}
//     </>
//   );
// };

// export default TrainingList;



import React, { useState, useEffect } from 'react';
import { Clock, Users, BarChart3, Book, MessageSquare, Star } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import API_URL from '../../constants/Constants';
import ReviewModal from '../Review/ReviewModal';

const TrainingList = ({ limit }) => {
  const navigate = useNavigate();
  const [trainings, setTrainings] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [trainingReviews, setTrainingReviews] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTrainingId, setSelectedTrainingId] = useState(null);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

  // Array of feature images
  const featureImages = [
    '/images/1.jpg',
    '/images/22.jpg',
    '/images/33.jpg',
    '/images/55.jpg',
    '/images/66.jpg',
    '/images/77.jpg'
  ];

  // Randomly assign feature images to trainings
  const [trainingImages, setTrainingImages] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch trainings
        const trainingsResponse = await fetch(`${API_URL}training/`);
        if (!trainingsResponse.ok) {
          throw new Error('Failed to fetch trainings');
        }
        const trainingsData = await trainingsResponse.json();
        setTrainings(Array.isArray(trainingsData) ? trainingsData : []);

        // Fetch categories
        const categoriesResponse = await fetch(`${API_URL}categories/`);
        if (!categoriesResponse.ok) {
          throw new Error('Failed to fetch categories');
        }
        const categoriesData = await categoriesResponse.json();
        setCategories(Array.isArray(categoriesData) ? categoriesData : []);

        // Randomly assign feature images to trainings
        const imageAssignments = {};
        trainingsData.forEach(training => {
          imageAssignments[training.id] =
            featureImages[Math.floor(Math.random() * featureImages.length)];
        });
        setTrainingImages(imageAssignments);

        // Start loading reviews in parallel after trainings are fetched
        const reviewPromises = trainingsData.map(training =>
          fetch(`${API_URL}reviews/training/${training.id}`)
            .then(response => response.json())
            .then(reviews => ({ trainingId: training.id, reviews }))
        );

        const reviewsResults = await Promise.all(reviewPromises);

        // Create a mapping of training IDs to their reviews
        const reviewsByTraining = reviewsResults.reduce((acc, { trainingId, reviews }) => {
          acc[trainingId] = reviews;
          return acc;
        }, {});

        setTrainingReviews(reviewsByTraining);
        setIsLoading(false);
      } catch (error) {
        console.error('Fetching data failed:', error);
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  const calculateReviewStats = (trainingId) => {
    const reviews = trainingReviews[trainingId] || [];
    if (reviews.length === 0) {
      return { averageRating: 0, reviewCount: 0 };
    }

    const totalStars = reviews.reduce((sum, review) => sum + review.stars, 0);
    const averageRating = (totalStars / reviews.length).toFixed(1);

    return {
      averageRating: parseFloat(averageRating),
      reviewCount: reviews.length
    };
  };

  const getLevelColor = (level) => {
    switch (level?.toLowerCase()) {
      case 'beginner':
        return 'bg-[#3DF28E]/10 text-[#3DF28E] border-[#3DF28E]/30';
      case 'intermediate':
        return 'bg-[#1150ef]/10 text-[#1150ef] border-[#1150ef]/30';
      case 'advanced':
        return 'bg-purple-50 text-purple-700 border-purple-200';
      default:
        return 'bg-gray-50 text-gray-700 border-gray-200';
    }
  };

  const getCategoryColor = () => {
    return 'bg-[#3DF28E]/90 text-[#1150ef] border-[#3DF28E]/30';
  };

  const renderRatingStars = (rating) => {
    return [1, 2, 3, 4, 5].map((star) => (
      <Star
        key={star}
        className={`w-4 h-4 ${star <= Math.round(rating)
          ? 'text-[#1150ef] fill-[#1150ef]'
          : 'text-gray-300'
          }`}
      />
    ));
  };

  const openReviewModal = (trainingId) => {
    setSelectedTrainingId(trainingId);
    setIsReviewModalOpen(true);
  };

  const closeReviewModal = () => {
    setIsReviewModalOpen(false);
    setSelectedTrainingId(null);
  };


  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-20">
        <div className="w-12 h-12 border-4 border-[#1150ef] border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-10 px-4 bg-red-50 rounded-lg text-red-600">
        Error loading trainings: {error}
      </div>
    );
  }

  if (!trainings?.length) {
    return (
      <div className="text-center py-10 px-4">
        <p className="text-gray-600">No trainings available at the moment.</p>
      </div>
    );
  }

  // Filter trainings by selected category
  const filteredTrainings = selectedCategory
    ? trainings.filter(training => training.category_id === selectedCategory)
    : trainings;

  const displayedTrainings = limit 
    ? filteredTrainings.slice(0, limit)
    : filteredTrainings;

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-20">
        <div className="w-12 h-12 border-4 border-[#1150ef] border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-10 px-4 bg-red-50 rounded-lg text-red-600">
        Error loading trainings: {error}
      </div>
    );
  }

  return (
    <div className="flex">
      {/* Category Sidebar */}
      <div className="w-64 p-6 bg-gray-50 border-r">
        <h2 className="text-xl font-bold mb-6 text-gray-800">Categories</h2>
        <div className="space-y-2">
          <button
            onClick={() => setSelectedCategory(null)}
            className={`w-full text-left px-4 py-2 rounded-lg transition-colors duration-200 ${
              selectedCategory === null 
                ? 'bg-[#1150ef] text-white' 
                : 'hover:bg-gray-200 text-gray-700'
            }`}
          >
            All Trainings
          </button>
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => setSelectedCategory(category.id)}
              className={`w-full text-left px-4 py-2 rounded-lg transition-colors duration-200 ${
                selectedCategory === category.id 
                  ? 'bg-[#1150ef] text-white' 
                  : 'hover:bg-gray-200 text-gray-700'
              }`}
            >
              {category.name}
            </button>
          ))}
        </div>
      </div>

      {/* Training List */}
      <div className="flex-1 p-6">
        {displayedTrainings.length === 0 ? (
          <div className="text-center py-10 px-4">
            <p className="text-gray-600">No trainings available in this category.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {displayedTrainings.map((training) => {
              const { averageRating, reviewCount } = calculateReviewStats(training.id);
              return (
                <div
                  key={training.id}
                  className="bg-white p-4 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 hover:-translate-y-2 overflow-hidden"
                >
                  {/* Training Image */}
                  <div className="relative overflow-hidden">
                    <img
                      src={trainingImages[training.id] || featureImages[0]}
                      alt={training.title}
                      className="w-full h-48 object-cover"
                    />
                    <span className={`absolute top-4 right-4 px-3 py-1 text-sm font-medium rounded-full border ${getCategoryColor()}`}>
                      {training.category.name}
                    </span>
                  </div>

                {/* Training Details */}
                <div className="mb-6 mt-4">
                   <h3 className="text-xl font-bold text-gray-900 hover:text-[#1150ef] transition-colors duration-200">
                     {training?.title}
                   </h3>
                   <p className="mt-2 text-gray-600 text-sm line-clamp-2 mt-2">
                     {training?.description}
                   </p>
                 </div>
              
                <div className="grid grid-cols-2 gap-4 mb-6">
                  <div className="flex items-center text-gray-600 hover:text-blue-600 transition-colors">
                    <Clock className="w-4 h-4 mr-2" />
                    <span className="text-sm">{training.duration} days</span>
                  </div>
                  <div className="flex items-center text-gray-600 hover:text-blue-600 transition-colors">
                    <Users className="w-4 h-4 mr-2" />
                    <span className="text-sm">{training.instructor}</span>
                  </div>
                  <div className="flex items-center text-gray-600 hover:text-blue-600 transition-colors">
                    <BarChart3 className="w-4 h-4 mr-2" />
                    <span className={`text-sm px-2 py-0.5 rounded-full border ${getLevelColor(training.level)}`}>
                      {training.level}
                    </span>
                  </div>
                  <div className="flex items-center text-gray-600 hover:text-blue-600 transition-colors">
                    <Book className="w-4 h-4 mr-2" />
                    <span className="text-sm">{training.is_certified ? 'Certified' : 'Non-Certified'}</span>
                  </div>
                 
                </div>

                {/* Reviews Section */}
                <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow-md border border-gray-200 mb-4">
                  <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between w-full">
                    {/* Review Title */}
                    <span className="font-semibold text-lg text-gray-800">Review</span>

                    {/* Rating stars and Average Rating */}
                    <div className="flex items-center mt-2 sm:mt-0 space-x-2">
                      <div className="flex">{renderRatingStars(averageRating)}</div>
                      <span className="text-sm text-gray-500">
                        {averageRating} ({reviewCount} reviews)
                      </span>
                    </div>
                  </div>
                </div>

                {/* Price and Action */}
                <div className="flex items-center justify-between pt-4 border-t border-gray-100">
                  <div>
                    <span className="text-sm text-gray-500">Course Fee</span>
                    <p className="text-xl font-bold text-gray-900">{training.fee} $</p>
                  </div>
                  <div className="flex flex-col items-center space-y-2">
                    <button
                      onClick={() => navigate(`/trainings/${training.id}`)}
                      className="inline-flex items-center px-4 py-2.5 bg-[#1150ef] text-white rounded-lg hover:bg-[#1150ef]/90 transition-colors duration-200 hover:scale-105 active:scale-95"
                    >
                      Learn More
                    </button>
                    <button
                      onClick={() => openReviewModal(training.id)}
                      className="inline-flex items-center px-4 py-2.5 bg-[#3DF28E] text-gray-900 rounded-lg hover:bg-[#3DF28E]/90 transition-colors duration-200 hover:scale-105 active:scale-95"
                    >
                      <MessageSquare className="w-4 h-4 mr-2" /> Review
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      </div>

      {/* Review Modal */}
      {isReviewModalOpen && (
        <ReviewModal
          trainingId={selectedTrainingId}
          isOpen={isReviewModalOpen}
          onClose={closeReviewModal}
          onReviewSubmit={() => {/* Optional: add any refresh logic */ }}
        />
      )}
    </div>
  );
};

export default TrainingList;